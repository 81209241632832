import { AuthError, EventType, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { b2cPolicies, loginRequest } from './utils/authConfig';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme and page variables */
import './App.css';
import Activity from './pages/Activity';
import Dashboard from './pages/Dashboard';
import Fats from './pages/Fats';
import FatsDispose from './pages/FatsDispose';
import FatsInspect from './pages/FatsInspect';
import FatsPump from './pages/FatsPump';
import Fse from './pages/Fse';
import Landing from './pages/Landing';
import Login from './pages/Login';
import ManageInvites from './pages/ManageInvites';
import ManageTrucks from './pages/ManageTrucks';
import ManageUsers from './pages/ManageUsers';
import ManageVendors from './pages/ManageVendors';
import Report from './pages/Report';
import ViewDisposal from './pages/ViewDisposal';
import ViewInspection from './pages/ViewInspection';
import ViewPump from './pages/ViewPump';

setupIonicReact();

interface AppProps {
  msalInstance: PublicClientApplication;
}

const App: React.FC<AppProps> = ({ msalInstance }) => {

  const { instance } = useMsal();

  const [unauthedRoute, setUnauthedRoute] = useState<string>('/landing');
  const [authedRoute, setAuthedRoute] = useState<string>('/landing');


  const handleSetRoles = (roleIn: any) => {
    if (roleIn === 1 || roleIn === 4) {
      setAuthedRoute('/dashboard');
    } else if (roleIn === 6) {
      setAuthedRoute('/fse');
    } else if (roleIn) {
      setAuthedRoute('/fats');
    }
  }


  useEffect(() => {
    const activeAccount = msalInstance.getActiveAccount();
    handleSetRoles(activeAccount?.idTokenClaims?.extension_APPRole);

    setUnauthedRoute('/login');

    const callbackId = msalInstance.addEventCallback((event: any) => {
      if (event.payload?.account?.idTokenClaims && (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)) {
        const idTokenClaims = event.payload.idTokenClaims as { [key: string]: any };

        handleSetRoles(idTokenClaims.extension_APPRole);

        if (idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
          const originalSignInAccount = msalInstance.getAllAccounts().find(
            (account) =>
              account.idTokenClaims?.oid === idTokenClaims.oid &&
              account.idTokenClaims?.sub === idTokenClaims.sub &&
              account.idTokenClaims?.tfp === b2cPolicies.names.signUpSignIn
          );

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          msalInstance.ssoSilent(signUpSignInFlowRequest);
        }

        if (
          idTokenClaims.hasOwnProperty('tfp') &&
          (idTokenClaims['tfp'].toLowerCase() === b2cPolicies.names.forgotPassword.toLowerCase() ||
            (idTokenClaims.hasOwnProperty('acr') &&
              idTokenClaims['acr'].toLowerCase() === b2cPolicies.names.forgotPassword.toLowerCase()))
        ) {
          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: loginRequest.scopes,
          };
          msalInstance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && (event.error as AuthError).errorMessage.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          msalInstance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);


  return (
    <MsalProvider instance={msalInstance}>
      <IonApp>
        <IonReactRouter>
          <Route path='/activity/:type?' component={Activity} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/fats' component={Fats} />
          <Route path='/fse' component={Fse} />
          <Route path='/fatsdispose' component={FatsDispose} />
          <Route path='/fatsinspect' component={FatsInspect} />
          <Route path='/fatspump' component={FatsPump} />
          <Route path='/landing' component={Landing} />
          <Route path='/login' component={Login} />
          <Route path='/manageinvites' component={ManageInvites} />
          <Route path='/managetrucks/:vendorId?' component={ManageTrucks} />
          <Route path='/manageusers' component={ManageUsers} />
          <Route path='/managevendors' component={ManageVendors} />
          <Route path='/report/:type?/:category?' component={Report} />
          <Route path='/viewdisposal/:disposalId' component={ViewDisposal} />
          <Route path='/viewinspection/:inspectionTypeId/:inspectionId' component={ViewInspection} />
          <Route path='/viewpump/:pumpId' component={ViewPump} />

          <AuthenticatedTemplate>
            <Redirect to={authedRoute} />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Redirect to={unauthedRoute} />
          </UnauthenticatedTemplate>
        </IonReactRouter>
      </IonApp>
    </MsalProvider>
  );
};

export default App;
