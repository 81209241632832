import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { b2cPolicies, msalConfig } from './utils/authConfig';

const startApp = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  await msalInstance.initialize();

  const redirectResponse = await msalInstance.handleRedirectPromise();
  if (redirectResponse?.account) {
    msalInstance.setActiveAccount(redirectResponse.account);
  }

  const activeAccount = msalInstance.getActiveAccount();
  if (activeAccount && activeAccount.environment !== b2cPolicies.authorityDomain) {
    msalInstance.setActiveAccount(null);
  }

  if (!msalInstance.getActiveAccount()) {
    for (const account of msalInstance.getAllAccounts()) {
      if (account.environment === b2cPolicies.authorityDomain) {
        msalInstance.setActiveAccount(account);
        break;
      }
    }
  }

  msalInstance.addEventCallback((event: any) => {
    if (event.payload?.account?.idTokenClaims && (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS)) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  });

  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Failed to find the root element');
  const root = createRoot(rootElement);

  root.render(
    <App msalInstance={msalInstance} />
  );

  serviceWorkerRegistration.unregister();
}

startApp();