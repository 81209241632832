import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import { PiBookOpenTextLight } from 'react-icons/pi';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../components/Header';
import PaginationTable from '../components/PaginationTable';
import SelectInput from '../components/SelectInput';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './Report.css';

type ReportType = 'inspections' | 'pumps' | 'disposals';
type InspectionType = 'all' | 'compliant' | 'failed'; // | 'overdue';

interface ReportParams {
  type?: ReportType;
}

const Report: React.FC = () => {

  const { type } = useParams<ReportParams>();
  const history = useHistory();
  const { instance } = useMsal();
  const { httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const inspectionUserRoleIds = [1, 3, 4, 6, 8];
  const pumpUserRoleIds = [1, 4, 5, 6];
  const disposalUserRoleIds = [1, 4, 5];

  const searchTypeMap: { [key: string]: string } = {
    inspections: 'Inspection',
    pumps: 'Pumpactivity',
    disposals: 'Disposal',
  }

  const inspectionTypeMap: { [key: number]: string } = {
    1: 'GGICert',
    2: 'HGICert',
  }

  const [loading, setLoading] = useState<boolean>(true);

  const [reportType, setReportType] = useState<ReportType>();
  const [reportTypeOptions, setReportTypeOptions] = useState<string[]>();
  const [inspectionType, setInspectionType] = useState<InspectionType>('all');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const [searchResults, setSearchResults] = useState<any[]>();
  const [searchResultsType, setSearchResultsType] = useState<ReportType>();

  const [downloadIndex, setDownloadIndex] = useState<number>(-1);


  useEffect(() => {
    if (type) setReportType(type);

    let accountClaims = instance.getActiveAccount()?.idTokenClaims;
    let role = accountClaims?.extension_APPRole;
    if (typeof role === 'number') {
      let options: string[] = [];
      if (inspectionUserRoleIds.includes(role)) options.push('inspections');
      if (pumpUserRoleIds.includes(role)) options.push('pumps');
      if (disposalUserRoleIds.includes(role)) options.push('disposals');
      setReportTypeOptions(options);
    } else {
      presentToast('A token error occurred. Please log out and try again.', 'badToast');
    }

    setLoading(false);
  }, []);


  const searchReports = async () => {
    if (searchLoading) return;

    if (!reportType) {
      presentToast('Please select a report type.', 'badToast');
      return;
    }

    setSearchResults(undefined);
    setSearchResultsType(reportType);
    setSearchLoading(true);

    let apiCallString = `${searchTypeMap[reportType]}?user=true`;
    if (reportType === 'inspections' && inspectionType !== 'all') {
      apiCallString += `&inspectionPassed=${inspectionType === 'compliant' ? true : false}`;
    }
    if (searchTerm !== '') {
      apiCallString += `&searchTerm=${searchTerm}`;
    }

    try {
      let response = await httpCall('GET', apiCallString);
      if (response.success === true && response.status === 200) {
        setSearchResults(response.data);
      } else {
        presentToast(`Failed to search ${reportType}.`, 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setSearchLoading(false);
  }


  const handleViewDetails = (index: number) => {
    if (searchResultsType === 'inspections') {
      history.push(`/viewinspection/${searchResults![index].inspectionTypeId}/${searchResults![index].inspectionId}`);
    } else if (searchResultsType === 'pumps') {
      history.push(`/viewpump/${searchResults![index].pumpActivityId}`);
    } else if (searchResultsType === 'disposals') {
      history.push(`/viewdisposal/${searchResults![index].disposalId}`);
    }
  }


  const handleDownloadReport = async (index: number) => {
    try {
      if (!searchResults || !searchResultsType) {
        presentToast('An error occurred.', 'badToast');
        return;
      }

      setDownloadIndex(index);

      let reqAction = {
        inspections: inspectionTypeMap[searchResults[index].inspectionTypeId] || '',
        pumps: 'pump',
        disposals: 'disposal',
      }[searchResultsType] || '';

      if (reqAction === '') {
        presentToast('A report type error occurred.', 'badToast');
        return;
      }

      let reqId = {
        inspections: searchResults[index].inspectionId,
        pumps: searchResults[index].pumpActivityId,
        disposals: searchResults[index].disposalId,
      }[searchResultsType] || -1;

      if (reqId === -1) {
        presentToast('A report id error occurred.', 'badToast');
        return;
      }

      let response = await httpCall('GET', 'Report', `${reqAction}?id=${reqId}`, undefined, undefined, true);
      if (response.success === true && response.status === 200) {
        let url = URL.createObjectURL(response.data);
        let anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${searchTypeMap[searchResultsType!]}_Report.pdf`; //TODO: couldn't extract name from content-disposition header...
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      } else {
        presentToast('Failed to generate report.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Error generating report.', 'badToast');
    }

    setDownloadIndex(-1);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Your Activity</span>

                <div className='defaultSection defaultMarginTop'>
                  <div className='defaultEditGrid'>
                    <span className='defaultMediumText'>Type:</span>
                    <SelectInput
                      sections={[{
                        options: reportTypeOptions ? Object.fromEntries(
                          reportTypeOptions.map((o: string) => [o.charAt(0).toUpperCase() + o.slice(1), o])
                        ) : {}
                      }]}
                      value={reportType}
                      setValue={setReportType}
                    />

                    {
                      reportType === 'inspections' ? <>
                        <span className='defaultMediumText'>Certification:</span>
                        <SelectInput
                          sections={[{ options: { 'All': 'all', 'Compliant': 'compliant', 'Failed': 'failed' } }]}
                          value={inspectionType}
                          setValue={setInspectionType}
                        />
                      </> : null
                    }

                    <span className='defaultMediumText'>Location Search Term (optional):</span>
                    <input className='textInput' value={searchTerm} onChange={(event: React.FocusEvent<HTMLInputElement>) => setSearchTerm(event.target.value)} autoComplete='off' spellCheck='false' type='text' placeholder='Search term' maxLength={50} />
                  </div>

                  <div className='smallButton' onClick={searchReports}>
                    {searchLoading ? <IonSpinner name='crescent' /> : <span>Search</span>}
                  </div>
                </div>

                {(searchResults && searchResultsType) ? <span className='defaultMediumText boldText mediumMarginTop'>{searchResultsType.charAt(0).toUpperCase() + searchResultsType.slice(1, -1)} Results</span> : null}

                {
                  searchResults ?
                    searchResults.length === 0 ? <span className='defaultMediumText defaultMarginTop'>No results!</span> :
                      <PaginationTable
                        cssClasses='defaultMarginTop'
                        labels={['Location', 'Date', (searchResultsType === 'inspections' ? 'Inspection Type' : 'Gal'), (searchResultsType === 'disposals' ? 'Hauler' : 'Status')]}
                        elements={
                          searchResults.map((result: any, i: number) => [
                            <div className='defaultTableElement'>
                              <span className='defaultMediumText'>
                                {
                                  {
                                    'inspections': result.fse?.name?.trim(),
                                    'pumps': result.fse?.name?.trim(),
                                    'disposals': result.wwtp?.wwtpName,
                                  }[searchResultsType!] || ''
                                }
                              </span>
                              <span className='defaultSmallText'>
                                {
                                  {
                                    'inspections': result.fse?.location?.trim(),
                                    'pumps': result.fse?.location?.trim(),
                                    'disposals': result.wwtp?.city,
                                  }[searchResultsType!] || ''
                                }
                              </span>
                            </div>,
                            <span className='defaultMediumText defaultTableElement'>
                              {
                                {
                                  'inspections': new Date(result.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true }),
                                  'pumps': new Date(result.datePumped).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true }),
                                  'disposals': new Date(result.disposalDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true }),
                                }[searchResultsType!] || ''
                              }
                            </span>,
                            <span className='defaultMediumText defaultTableElement'>
                              {
                                {
                                  'inspections': result.inspectionType?.type,
                                  'pumps': result.greaseGal,
                                  'disposals': result.volumeDisposed,
                                }[searchResultsType!] || ''
                              }
                            </span>,
                            <span className='defaultMediumText defaultTableElement'>
                              {
                                {
                                  'inspections': (result.inspectionPassed === true ? 'Pass' : 'Fail'),
                                  'pumps': (result.isDisposed ? 'Disposed' : 'Undisposed'),
                                  'disposals': result.gwh?.attributes?.displayName,
                                }[searchResultsType!] || ''
                              }
                            </span>,
                            <div className='defaultTableButtonWrapper defaultTableElement'>
                              <div className='defaultTableButton' onClick={() => handleViewDetails(i)}>
                                <PiBookOpenTextLight className='defaultTableButtonIcon' />
                                <span className='defaultMediumText'>Details</span>
                              </div>
                              <div className='defaultTableButton' onClick={() => handleDownloadReport(i)}>
                                {
                                  downloadIndex === i ? <IonSpinner name='crescent' /> : <>
                                    <BsDownload className='defaultTableButtonIcon' />
                                    <span className='defaultMediumText'>Download</span>
                                  </>
                                }
                              </div>
                            </div>,
                          ])
                        }
                      />
                    : null
                }
              </>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Report;
