import { IonContent, IonPage, IonSpinner, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineModeEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import PaginationTable from '../components/PaginationTable';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './ManageVendors.css';

const ManageVendors: React.FC = () => {

  const [presentAlert] = useIonAlert();
  const { result, httpCall } = useHttpCall();
  const history = useHistory();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [editVendorData, setEditVendorData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [saveVendorLoading, setSaveVendorLoading] = useState<boolean>(false);

  const [createVendorMode, setCreateVendorMode] = useState<boolean>(false);

  const [editVendorIndex, setEditVendorIndex] = useState<number>(-1);

  const [toggleVendorIndex, setToggleVendorIndex] = useState<number>(-1);


  useEffect(() => {
    if (result !== null) initManageVendors();
  }, [result]);


  const initManageVendors = async () => {
    try {
      let response = await httpCall('GET', 'Vendor?trucks=true');
      if (response.success === true && response.status === 200) {
        setTableData(response.data);
      } else {
        presentToast('Error getting vendors.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    } finally {
      setLoading(false);
    }
  }


  const handleCreateVendor = () => {
    setCreateVendorMode(true);
    setEditVendorData({
      name: '',
    });
  }


  const handleClickEditVendor = (index: number) => {
    setEditVendorIndex(index);
    setEditVendorData(tableData[index]);
  }


  const handleEditVendor = (keyIn: string, valueIn: string) => {
    let editVendorDataCopy = JSON.parse(JSON.stringify(editVendorData));
    editVendorDataCopy[keyIn] = valueIn;
    setEditVendorData(editVendorDataCopy);
  }


  const handleSubmitVendor = async () => {
    if (editVendorData === undefined) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    setSaveVendorLoading(true);

    try {
      let response = await httpCall('POST', 'Vendor', undefined, undefined, editVendorData);
      if (response.success === true && response.status === 201) {
        let tableDataCopy = tableData.slice();
        setTableData(tableDataCopy.concat(response.data));
        setCreateVendorMode(false);
        setEditVendorData(undefined);
      } else {
        presentToast('Failed to create vendor.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setSaveVendorLoading(false);
  }


  const handleSaveVendor = async () => {
    if (editVendorData === undefined) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    setSaveVendorLoading(true);

    if (!Object.entries(editVendorData).some(([key, value]) => key !== 'users' && key !== 'trucks' && tableData[editVendorIndex][key] !== value)) {
      setEditVendorIndex(-1);
      setEditVendorData(undefined);
    } else {
      try {
        let response = await httpCall('PUT', 'Vendor', undefined, editVendorData.vendorId, editVendorData);
        if (response.success === true && response.status === 204) {
          let tableDataCopy = tableData.slice();
          tableDataCopy[editVendorIndex] = editVendorData;
          setTableData(tableDataCopy);
          setEditVendorIndex(-1);
          setEditVendorData(undefined);
        } else {
          presentToast('Failed to save vendor data', 'badToast');
        }
      } catch (e) {
        console.log(e);
        presentToast('An error occurred', 'badToast');
      }
    }

    setSaveVendorLoading(false);
  }


  const handleDisableVendor = (indexIn: number) => {
    presentAlert({
      header: `Are you sure you want to disable the vendor ${tableData[indexIn].name ?? '-'}?`,
      buttons: [
        { text: 'Cancel', role: 'cancel', },
        {
          text: 'Disable', role: 'confirm', handler: async () => {
            try {
              if (toggleVendorIndex !== -1) return;

              setToggleVendorIndex(indexIn);

              let response = await httpCall('PUT', 'Vendor', 'deactivate', tableData[indexIn].vendorId);
              if (response.success === true && response.status === 200) {
                let tableDataCopy = tableData.slice();
                tableDataCopy.splice(indexIn, 1);
                setTableData(tableDataCopy);
              } else {
                presentToast('Failed to disable vendor.', 'badToast');
              }
            } catch (e) {
              console.log(e);
              presentToast('An error occurred.', 'badToast');
            }

            setToggleVendorIndex(-1);
          },
        },
      ],
    });
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Manage Vendors</span>

                <div className='defaultSubsection defaultMarginTop'>
                  <input className='textInput' value={searchTerm} onChange={(event: React.FocusEvent<HTMLInputElement>) => setSearchTerm(event.target.value)} autoComplete='off' spellCheck='false' type='text' placeholder='Search Vendor' maxLength={50} />

                  <div className='smallButton marginLeftAuto' onClick={handleCreateVendor}>
                    <span>New Vendor</span>
                  </div>
                </div>

                {
                  tableData.length === 0 ? null : <PaginationTable
                    cssClasses='defaultMarginTop'
                    labels={['Name']}
                    elements={
                      tableData
                        .filter((vendor: { [key: string]: any }) =>
                          Object.values(vendor).some(value => (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())))
                        )
                        .map((vendor: { [key: string]: any }, index: number) => [
                          <span className='defaultMediumText defaultTableElement'>{vendor.name}</span>,
                          <div className='defaultTableButtonWrapper defaultTableElement'>
                            <div className='defaultTableButton' onClick={() => handleClickEditVendor(index)}>
                              <MdOutlineModeEdit className='defaultTableButtonIcon' />
                              <span className='defaultMediumText'>Edit</span>
                            </div>
                            <div className='defaultTableButton' onClick={() => handleDisableVendor(index)}>
                              {
                                toggleVendorIndex === index ? <IonSpinner name='crescent' /> : <>
                                  <IoMdCloseCircleOutline className='defaultTableButtonIcon' />
                                  <span className='defaultMediumText'>Disable</span>
                                </>
                              }
                            </div>
                          </div>,
                        ])
                    }
                  />
                }
              </>
          }
        </div>

        {
          (editVendorIndex !== -1 && editVendorData) ?
            <div className='popupWrapper' onClick={() => { setEditVendorIndex(-1); setEditVendorData(undefined); }}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>{editVendorData.name}</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop'>
                  <span className='defaultMediumText'>Name:</span>
                  <input className='textInput' type='text' placeholder='Name' value={editVendorData.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditVendor('name', e.target.value)} />
                </div>

                <div className='defaultSectionBot'>
                  <span className='defaultMediumText boldText'>Vendor Trucks:</span>
                  {
                    editVendorData.trucks?.length === 0 ?
                      <span className='defaultMediumText'>There are no trucks for this vendor.</span>
                      :
                      editVendorData.trucks.map((truck: { [key: string]: any }) =>
                        <span key={truck.truckId} className='defaultMediumText'>• {truck.tag}</span>
                      )
                  }
                  <div className='smallButton' onClick={() => history.push(`managetrucks/${editVendorData.vendorId}`)}>
                    <span>Edit Trucks</span>
                  </div>
                </div>

                <div className='smallButtonWrapper defaultMarginTop'>
                  <div className='smallButton defaultMarginTop' onClick={() => { setEditVendorIndex(-1); setEditVendorData(undefined); }}>
                    <span>Cancel</span>
                  </div>
                  <div className='smallButton defaultMarginTop' onClick={handleSaveVendor}>
                    {saveVendorLoading ? <IonSpinner name='crescent' /> : <span>Save</span>}
                  </div>
                </div>
              </div>
            </div> : null
        }

        {
          (createVendorMode && editVendorData) ?
            <div className='popupWrapper' onClick={() => { setCreateVendorMode(false); setEditVendorData(undefined); }}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>New Vendor</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop'>
                  <span className='defaultMediumText'>Name:</span>
                  <input className='textInput' type='text' placeholder='Name' value={editVendorData.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditVendor('name', e.target.value)} />
                </div>
                <span className='defaultSmallText'>Trucks can be added after vendor creation.</span>

                <div className='smallButtonWrapper mediumMarginTop'>
                  <div className='smallButton' onClick={() => { setCreateVendorMode(false); setEditVendorData(undefined); }}>
                    <span>Cancel</span>
                  </div>
                  <div className='smallButton' onClick={handleSubmitVendor}>
                    {saveVendorLoading ? <IonSpinner name='crescent' /> : <span>Add</span>}
                  </div>
                </div>
              </div>
            </div> : null
        }
      </IonContent>
    </IonPage>
  );
};

export default ManageVendors;
