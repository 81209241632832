import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../components/Header';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './ViewDisposal.css';

interface ViewDisposal {
  disposalId: string;
}

const ViewDisposal: React.FC = () => {

  const { disposalId } = useParams<ViewDisposal>();
  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [disposalData, setDisposalData] = useState<any>();

  const [downloading, setDownloading] = useState<boolean>(false);


  useEffect(() => {
    if (result !== null) initViewDisposal();
  }, [result]);


  const initViewDisposal = async () => {
    try {
      let response = await httpCall('GET', 'Disposal', undefined, disposalId);
      if (response.success === true && response.status === 200) {
        if (response.data.gwh?.attributes) {
          if (response.data.gwh.attributes.firstName === 'Detached user') {
            response.data.gwh.attributes.fullName = response.data.gwh.attributes.firstName;
          } else if (response.data.gwh.attributes.firstName && response.data.gwh.attributes.lastName) {
            response.data.gwh.attributes.fullName = `${response.data.gwh.attributes.firstName} ${response.data.gwh.attributes.lastName}`;
          } else {
            response.data.gwh.attributes.fullName = response.data.gwh.attributes.firstName ?? response.data.gwh.attributes.lastName ?? '-';
          }
        }

        setDisposalData(response.data);
      } else {
        presentToast('Error getting disposal data.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setLoading(false);
  }


  const handleDownloadReport = async () => {
    if (downloading) return;

    setDownloading(true);

    try {
      let response = await httpCall('GET', 'Report', `disposal?id=${disposalId}`, undefined, undefined, true);
      if (response.success === true && response.status === 200) {
        let url = URL.createObjectURL(response.data);
        let anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Disposal_Report.pdf'; //TODO: couldn't extract name from content-disposition header...
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      } else {
        presentToast('Failed to generate report.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Error generating report.', 'badToast');
    }

    setDownloading(false);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              disposalData ?
                <>
                  <span className='defaultLargeText'>Disposal Details</span>

                  <div className='defaultSection defaultMarginTop'>
                    <span className='defaultMediumText'><span className='boldText'>Location:</span> {disposalData.wwtp?.wwtpName}</span>

                    <span className='defaultMediumText'><span className='boldText'>Date and Time:</span> {new Date(disposalData.disposalDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true })}</span>

                    <span className='defaultMediumText'><span className='boldText'>Grease Waste Hauler Name:</span> {disposalData.gwh?.attributes?.fullName}</span>

                    <span className='defaultMediumText'><span className='boldText'>Grease Waste Hauler Vendor:</span> {disposalData.gwh?.vendor?.name ?? 'N/A'}</span>

                    <span className='defaultMediumText'><span className='boldText'>Pump Activities:</span> {disposalData.pumpactivities.length === 0 ? '' : disposalData.pumpactivities.length}</span>

                    {
                      disposalData.pumpactivities?.map((pump: any) => <span key={pump.pumpActivityId} className='defaultMediumText'>• <span className='boldText'>{pump.fse?.name}:</span> {new Date(pump.datePumped).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true })} - <span className='linkText' onClick={() => history.push(`/viewpump/${pump.pumpActivityId}`)}>View</span></span>)
                    }
                  </div>

                  <div className='smallButtonWrapper mediumMarginTop'>
                    <div className='smallButton' onClick={() => history.goBack()}>
                      <span>Back</span>
                    </div>
                    <div className='smallButton' onClick={handleDownloadReport}>
                      {downloading ? <IonSpinner name='crescent' /> : <span>Download</span>}
                    </div>
                  </div>
                </> : null
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ViewDisposal;
