import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useCallback } from 'react';
import { protectedResources } from './authConfig';

const useHttpCall = () => {
  const { instance } = useMsal();

  const { result, error } = useMsalAuthentication(InteractionType.Redirect, { scopes: protectedResources.FatsApi.scopes, account: instance.getActiveAccount()!, });// redirectUri: '/redirect', });


  const httpCall = async (reqMethod: 'GET' | 'POST' | 'PUT' | 'DELETE', endpoint: string, reqAction?: string, reqId?: number | string, reqData?: any, returnBlob?: boolean) => {
    try {
      if (error !== null) {
        return {
          success: false,
          data: {
            msg: 'Authentication error.',
          }
        };
      }

      let apiToken = result?.accessToken;

      let currentDate = new Date();

      if (!result || !apiToken || !result.expiresOn || result.expiresOn < currentDate) {
        let activeAccount = instance.getActiveAccount();

        if (!activeAccount) {
          return {
            success: false,
            data: { msg: 'Account error. Please log out and try again.', }
          };
        }

        let apiTokenResponse = await instance.acquireTokenSilent({
          scopes: [`https://BargeB2CAuth.onmicrosoft.com/8884eebb-768e-4680-8543-66982a8b5b97/Fats-API`],
          account: activeAccount,
        });

        if (!apiTokenResponse.accessToken) {
          return {
            success: false,
            data: { msg: 'Authentication error.', }
          };
        }

        apiToken = apiTokenResponse.accessToken;
      }

      let reqUrl = `${process.env.REACT_APP_API_URL}${endpoint}`;
      if (reqAction) {
        reqUrl = `${reqUrl}/${reqAction}`;
      }
      if (reqId) {
        reqUrl = `${reqUrl}/${reqId}`;
      }

      let headers: HeadersInit = {
        Authorization: `Bearer ${apiToken}`,
      };
      if (reqData) {
        headers['Content-Type'] = 'application/json';
      }
      if (returnBlob) {
        headers.Accept = 'application/pdf'
      }

      let req: RequestInit = {
        method: reqMethod,
        headers: headers,
      };

      if (reqData && req.headers) {
        req.body = JSON.stringify(reqData);
      }

      let response = await fetch(reqUrl, req);

      let responseData = null;
      if (response.ok) {
        if (returnBlob) {
          responseData = await response.blob();
        } else if (response.headers.get('content-type')?.includes('application/json')) {
          responseData = await response.json();
        }
      }

      return {
        success: response.ok,
        status: response.status,
        data: responseData,
      };
    } catch (e: any) {
      console.log(e);
      return {
        success: false,
        data: { msg: e.message, }
      };
    }
  };


  return {
    result,
    httpCall: useCallback(httpCall, [result, error]),
  };
};

export default useHttpCall;
