import { memo } from 'react';
import './ToggleSwitch.css';

interface ToggleSwitchProps {
  toggleId: string;
  isOn: boolean;
  handleToggle: any;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ toggleId, isOn, handleToggle }) => {

  return (
    <div className='switchWrapper'>
      <input className='switchCheckbox' id={toggleId} type='checkbox' checked={isOn} onChange={() => handleToggle(!isOn)} />
      <label className={`switchLabel${isOn ? ' switchLabelOn' : ''}`} htmlFor={toggleId}>
        <span className='switchButton' />
      </label>
    </div>
  );
};

export default memo(ToggleSwitch);
