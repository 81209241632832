import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner, useIonAlert } from '@ionic/react';
import { Fragment, useEffect, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { IoRefresh } from 'react-icons/io5';
import { MdOutlineModeEdit } from 'react-icons/md';
import Header from '../components/Header';
import PaginationTable from '../components/PaginationTable';
import SelectInput from '../components/SelectInput';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './ManageInvites.css';

const ManageInvites: React.FC = () => {

  const { instance } = useMsal();
  const [presentAlert] = useIonAlert();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const emailExpression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const vendorRoleIds = [5, 8];

  const [loading, setLoading] = useState<boolean>(true);

  const [userRole, setUserRole] = useState<number>(0);
  const [userOrg, setUserOrg] = useState<number>(0);

  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [orgData, setOrgData] = useState<{ [key: string]: any }[]>([]);
  const [roleData, setRoleData] = useState<{ [key: string]: any }[]>([]);
  const [vendorData, setVendorData] = useState<{ [key: string]: any }[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [editInviteData, setEditInviteData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [saveInviteLoading, setSaveInviteLoading] = useState<boolean>(false);

  const [createInviteMode, setCreateInviteMode] = useState<boolean>(false);

  const [editInviteIndex, setEditInviteIndex] = useState<number>(-1);

  const [resendIndex, setResendIndex] = useState<number>(-1);

  const [deleteIndex, setDeleteIndex] = useState<number>(-1);

  const [inviteErrorData, setInviteErrorData] = useState<{ [key: string]: any }[] | undefined>(undefined);


  useEffect(() => {
    if (result !== null) initManageInvites();
  }, [result]);


  const initManageInvites = async () => {
    try {
      let accountClaims = instance.getActiveAccount()?.idTokenClaims;
      let roleId = accountClaims?.extension_APPRole;
      let orgId = accountClaims?.extension_APPOrg;
      if (typeof roleId === 'number' && typeof orgId === 'number') { //this is so dumb, extension_APPRole and extension_APPOrg are always numbers and never {}, but it says theyre {}....
        setUserRole(roleId);
        setUserOrg(orgId);

        let [userInvitationResponse, orgResponse, roleResponse, vendorResponse] = await Promise.all([
          httpCall('GET', 'UserInvitation', 'all'),
          httpCall('GET', 'Org'),
          httpCall('GET', 'Role'),
          httpCall('GET', 'Vendor'),
        ]);

        if (userInvitationResponse.success === true && userInvitationResponse.status === 200) {
          setTableData(userInvitationResponse.data);
        } else {
          presentToast('Error getting invitations.', 'badToast');
        }

        if (orgResponse.success === true && orgResponse.status === 200) {
          setOrgData(orgResponse.data);
        } else {
          presentToast('Error getting organizations.', 'badToast');
        }

        if (roleResponse.success === true && roleResponse.status === 200) {
          setRoleData(roleResponse.data);
        } else {
          presentToast('Error getting user roles.', 'badToast');
        }

        if (vendorResponse.success === true && vendorResponse.status === 200) {
          setVendorData(vendorResponse.data);
        } else {
          presentToast('Error getting vendors.', 'badToast');
        }
      } else {
        presentToast('A token error occurred. Please log out and try again.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setLoading(false);
  }


  const handleCreateInvite = () => {
    setCreateInviteMode(true);
    setEditInviteData({
      email: '',
      roleId: roleData[0].roleId,
      vendorId: -1,
    });
  }


  const handleClickEditUser = (index: number) => {
    setEditInviteIndex(index);
    setEditInviteData({ ...tableData[index], vendorId: (tableData[index].vendorId === null ? -1 : tableData[index].vendorId) });
  }


  const handleEditInvite = (keyIn: string, valueIn: string | number) => {
    let editInviteDataCopy = JSON.parse(JSON.stringify(editInviteData));
    editInviteDataCopy[keyIn] = valueIn;
    setEditInviteData(editInviteDataCopy);
  }


  const handleSendInvite = async () => {
    if (editInviteData === undefined) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    let emailArray = editInviteData.email.split(',').map((email: string) => email.trim());

    if (emailArray.length === 0) {
      presentToast('Please enter a valid email', 'badToast');
      return;
    }

    for (let email of emailArray) {
      if (tableData.find((invite: { [key: string]: any }) => (invite.email === email && invite.orgId === editInviteData.orgId))) {
        presentToast(`The email ${email} has already been invited to this organization.`, 'badToast');
        return;
      }
      if (!emailExpression.test(email)) {
        presentToast(`The email ${email} is invalid.`, 'badToast');
        return;
      }
    }

    setSaveInviteLoading(true);

    try {
      let preppedInviteData: { [key: string]: any } = { ...editInviteData, vendorId: (editInviteData.vendorId === -1 ? null : editInviteData.vendorId) };

      let response = await httpCall('POST', 'UserInvitation', undefined, undefined, { ...preppedInviteData, email: emailArray });
      if (response.success === true && response.status === 200) {
        setCreateInviteMode(false);

        let inviteFails = response.data.filter((invite: { [key: string]: any }) => invite.success === false);
        let inviteSuccesses = response.data.filter((invite: { [key: string]: any }) => invite.success === true);

        let newInvites = inviteSuccesses.map((invite: { [key: string]: any }) => ({ ...preppedInviteData, email: invite.email, invitationCode: invite.inviteCode, invitationId: invite.invitationId, orgId: invite.orgId }));
        let tableDataCopy = tableData.slice();
        setTableData(tableDataCopy.concat(newInvites));

        setEditInviteData(undefined);

        if (inviteFails.length > 0) {
          presentToast(`An error occurred while sending invitation${emailArray.length === 1 ? '' : 's'}.`, 'badToast');
          setInviteErrorData(inviteFails);
        } else {
          presentToast(`Invitation${emailArray.length === 1 ? '' : 's'} successfully sent.`, 'goodToast');
        }
      } else {
        presentToast(`Failed to send invitation${emailArray.length === 1 ? '' : 's'}.`, 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setSaveInviteLoading(false);
  }


  const handleSaveInvite = async () => {
    if (editInviteData === undefined) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    if (!emailExpression.test(editInviteData.email)) {
      presentToast('Please enter a valid email.', 'badToast');
      return;
    }

    setSaveInviteLoading(true);

    let preppedInviteData: { [key: string]: any } = { ...editInviteData, vendorId: (editInviteData.vendorId === -1 ? null : editInviteData.vendorId) };

    if (Object.entries(preppedInviteData).some(([key, value]) => tableData[editInviteIndex][key] !== value)) {
      try {
        let { invitationId, ...userInvitationData } = preppedInviteData;
        let response = await httpCall('PUT', 'UserInvitation', undefined, invitationId, userInvitationData);
        if (response.success === true && response.status === 200) {
          let tableDataCopy = tableData.slice();
          tableDataCopy[editInviteIndex] = preppedInviteData;
          setTableData(tableDataCopy);
          setEditInviteIndex(-1);
          setEditInviteData(undefined);
        } else {
          presentToast('Failed to save invite.', 'badToast');
        }
      } catch (e) {
        console.log(e);
        presentToast('Invite failed to save.', 'badToast');
      }
    } else {
      setEditInviteIndex(-1);
      setEditInviteData(undefined);
    }

    setSaveInviteLoading(false);
  }


  const handleResendInvite = async (indexIn: number) => {
    if (resendIndex !== -1) return;

    setResendIndex(indexIn);

    try {
      let response = await httpCall('GET', 'UserInvitation', 'resend', tableData[indexIn].invitationId);
      if (response.success === true && response.status === 200) {
        presentToast('Invite was resent.', 'goodToast');
      } else {
        presentToast('Failed to resend invite.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Invite failed to resend.', 'badToast');
    }

    setResendIndex(-1);
  }


  const handleDeleteInvite = (indexIn: number) => {
    presentAlert({
      header: `Are you sure you want to delete the invite sent to ${tableData[indexIn].email ?? 'this user'}?`,
      buttons: [
        { text: 'Cancel', role: 'cancel', },
        {
          text: 'Delete', role: 'confirm', handler: async () => {
            try {
              if (deleteIndex !== -1) return;

              setDeleteIndex(indexIn);

              let response = await httpCall('DELETE', 'UserInvitation', undefined, tableData[indexIn].invitationId);
              if (response.success === true && response.status === 200) {
                let tableDataCopy = tableData.slice();
                tableDataCopy.splice(indexIn, 1);
                setTableData(tableDataCopy);
              } else {
                presentToast('Failed to delete invite', 'badToast');
              }
            } catch (e) {
              console.log(e);
              presentToast('An error occurred', 'badToast');
            }

            setDeleteIndex(-1);
          },
        },
      ],
    });
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Manage Invites</span>

                <div className='defaultSubsection defaultMarginTop'>
                  <input className='textInput' value={searchTerm} onChange={(event: React.FocusEvent<HTMLInputElement>) => setSearchTerm(event.target.value)} autoComplete='off' spellCheck='false' type='text' placeholder='Search Invite' maxLength={50} />

                  <div className='smallButton marginLeftAuto' onClick={handleCreateInvite}>
                    <span>New Invite</span>
                  </div>
                </div>

                {
                  tableData.length === 0 ? null : <PaginationTable
                    cssClasses='defaultMarginTop'
                    labels={['Email', 'Code', 'Organization', 'Role', 'Vendor']}
                    elements={
                      tableData
                        .filter((invite: { [key: string]: any }) =>
                          Object.values(invite).some(value => (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())))
                          || orgData.find((org: { [key: string]: any }) => org.orgId === invite.orgId)?.orgName.toLowerCase().includes(searchTerm.toLowerCase())
                          || roleData.find((role: { [key: string]: any }) => role.roleId === invite.roleId)?.roleName.toLowerCase().includes(searchTerm.toLowerCase())
                          || vendorData.find((vendor: { [key: string]: any }) => vendor.vendorId === invite.vendorID)?.vendorName.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((invite: { [key: string]: any }, index: number) => [
                          <span className='defaultMediumText defaultTableElement'>{invite.email ?? '-'}</span>,
                          <span className='defaultMediumText defaultTableElement'>{invite.invitationCode ?? '-'}</span>,
                          <span className='defaultMediumText defaultTableElement'>{orgData.find((org: { [key: string]: any }) => org.orgId === invite.orgId)?.orgName ?? '-'}</span>,
                          <span className='defaultMediumText defaultTableElement'>{roleData.find((role: { [key: string]: any }) => role.roleId === invite.roleId)?.roleName ?? '-'}</span>,
                          <span className='defaultMediumText defaultTableElement'>{vendorData.find((vendor: { [key: string]: any }) => vendor.vendorId === invite.vendorId)?.name ?? 'N/A'}</span>,
                          <div className='invitesTableButtonWrapper defaultTableElement'>
                            <div className='defaultTableButton' onClick={() => handleClickEditUser(index)}>
                              <MdOutlineModeEdit className='defaultTableButtonIcon' />
                              <span className='defaultMediumText'>Edit</span>
                            </div>
                            <div className='defaultTableButton' onClick={() => handleResendInvite(index)}>
                              {
                                resendIndex === index ? <IonSpinner name='crescent' /> : <>
                                  <IoRefresh className='defaultTableButtonIcon' />
                                  <span className='defaultMediumText'>Resend</span>
                                </>
                              }
                            </div>
                            <div className='defaultTableButton' onClick={() => handleDeleteInvite(index)}>
                              {
                                deleteIndex === index ? <IonSpinner name='crescent' /> : <>
                                  <IoMdCloseCircleOutline className='defaultTableButtonIcon' />
                                  <span className='defaultMediumText'>Delete</span>
                                </>
                              }
                            </div>
                          </div>,
                        ])
                    }
                  />
                }
              </>
          }
        </div>

        {
          (editInviteIndex !== -1 && editInviteData) ?
            <div className='popupWrapper' onClick={() => { setEditInviteIndex(-1); setEditInviteData(undefined); }}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>Invite Code: {editInviteData.invitationCode}</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop'>
                  <span className='defaultMediumText'>Organization:</span>
                  <span className='defaultMediumText'>{orgData.find((org: { [key: string]: any }) => org.orgId === userOrg)?.orgName ?? '-'}</span>

                  <span className='defaultMediumText'>Email:</span>
                  <input className='textInput' type='text' placeholder='Email' value={editInviteData.email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditInvite('email', e.target.value)} />

                  <span className='defaultMediumText'>Role:</span>
                  <SelectInput
                    sections={[{ options: Object.fromEntries(roleData.filter(role => !(userRole !== 1 && role.roleId === 1)).map(role => [role.roleName, role.roleId])) }]}
                    value={editInviteData.roleId}
                    setValue={(value: number) => handleEditInvite('roleId', value)}
                  />

                  {
                    !vendorRoleIds.includes(editInviteData.roleId) ? null : <>
                      <span className='defaultMediumText'>Vendor:</span>
                      <SelectInput
                        sections={[{
                          options: {
                            'N/A': -1,
                            ...Object.fromEntries(vendorData.map(vendor => [vendor.name, vendor.vendorId])),
                          }
                        }]}
                        value={editInviteData.vendorId ?? -1}
                        setValue={(value: number) => handleEditInvite('vendorId', value)}
                      />
                    </>
                  }
                </div>

                <div className='smallButtonWrapper defaultMarginTop'>
                  <div className='smallButton defaultMarginTop' onClick={() => { setEditInviteIndex(-1); setEditInviteData(undefined); }}>
                    <span>Cancel</span>
                  </div>
                  <div className='smallButton defaultMarginTop' onClick={handleSaveInvite}>
                    {saveInviteLoading ? <IonSpinner name='crescent' /> : <span>Save</span>}
                  </div>
                </div>
              </div>
            </div> : null
        }

        {
          (createInviteMode && editInviteData) ?
            <div className='popupWrapper' onClick={() => { setCreateInviteMode(false); setEditInviteData(undefined); }}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>New Invite</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop'>
                  <span className='defaultMediumText'>Organization:</span>
                  <span className='defaultMediumText'>{orgData.find((org: { [key: string]: any }) => org.orgId === userOrg)?.orgName ?? '-'}</span>

                  <span className='defaultMediumText'>Email:</span>
                  <input className='textInput' type='text' placeholder='Email' value={editInviteData.email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditInvite('email', e.target.value)} />

                  <span className='defaultMediumText'>Role:</span>
                  <SelectInput
                    sections={[{ options: Object.fromEntries(roleData.filter(role => !(userRole !== 1 && role.roleId === 1)).map(role => [role.roleName, role.roleId])) }]}
                    value={editInviteData.roleId}
                    setValue={(value: number) => handleEditInvite('roleId', value)}
                  />

                  {
                    !vendorRoleIds.includes(editInviteData.roleId) ? null : <>
                      <span className='defaultMediumText'>Vendor:</span>
                      <SelectInput
                        sections={[{
                          options: {
                            'N/A': -1,
                            ...Object.fromEntries(vendorData.map(vendor => [vendor.name, vendor.vendorId])),
                          }
                        }]}
                        value={editInviteData.vendorId ?? -1}
                        setValue={(value: number) => handleEditInvite('vendorId', value)}
                      />
                    </>
                  }
                </div>

                <div className='smallButtonWrapper mediumMarginTop'>
                  <div className='smallButton' onClick={() => { setCreateInviteMode(false); setEditInviteData(undefined); }}>
                    <span>Cancel</span>
                  </div>
                  <div className='smallButton' onClick={handleSendInvite}>
                    {saveInviteLoading ? <IonSpinner name='crescent' /> : <span>Send</span>}
                  </div>
                </div>
              </div>
            </div> : null
        }

        {
          inviteErrorData ?
            <div className='popupWrapper' onClick={() => setInviteErrorData(undefined)}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>Invitation Results</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop' style={{ gap: '0.5rem' }}>
                  {
                    inviteErrorData.length === 0 ? <span className='defaultMediumText'>Error getting invitation validation messages.</span> :
                      inviteErrorData.map((invite: { [key: string]: any }, index: number) => <Fragment key={index}>
                        <span className={`defaultMediumText${index > 0 ? ' mediumMarginTop' : ''}`}>Email:</span>
                        <span className={`defaultMediumText${index > 0 ? ' mediumMarginTop' : ''}`}>{invite.email}</span>

                        <span className='defaultMediumText'>Status:</span>
                        <span className={`defaultMediumText ${invite.success ? 'successText' : 'dangerText'}`}>{invite.errorMessage}</span>
                      </Fragment>)
                  }
                </div>

                <div className='smallButton mediumMarginTop' onClick={() => setInviteErrorData(undefined)}>
                  <span>Close</span>
                </div>
              </div>
            </div> : null
        }
      </IonContent>
    </IonPage>
  );
};

export default ManageInvites;
