import { IonContent, IonPage, IonSpinner, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineModeEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import PaginationTable from '../components/PaginationTable';
import SelectInput from '../components/SelectInput';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './ManageTrucks.css';

interface ManageTrucksParams {
  vendorId?: string;
}

const ManageTrucks: React.FC = () => {

  const { vendorId } = useParams<ManageTrucksParams>();
  const [presentAlert] = useIonAlert();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [vendorData, setVendorData] = useState<{ [key: string]: any }[]>([]);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);

  const [selectedVendorId, setSelectedVendorId] = useState<number>();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [editTruckData, setEditTruckData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [saveTruckLoading, setSaveTruckLoading] = useState<boolean>(false);

  const [createTruckMode, setCreateTruckMode] = useState<boolean>(false);

  const [editTruckIndex, setEditTruckIndex] = useState<number>(-1);

  const [toggleTruckIndex, setToggleTruckIndex] = useState<number>(-1);


  useEffect(() => {
    if (result !== null) initManageTrucks();
  }, [result]);


  const initManageTrucks = async () => {
    try {
      let response = await httpCall('GET', 'Vendor?trucks=true');
      if (response.success === true && response.status === 200) {
        setVendorData(response.data);

        const vendorIdNumber = vendorId ? parseInt(vendorId, 10) : undefined;
        filterTableData(vendorIdNumber, response.data);
      } else {
        presentToast('Error getting vendors and trucks.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    } finally {
      setLoading(false);
    }
  }


  const filterTableData = (vendorIdIn: number | undefined, vendorDataIn?: { [key: string]: any }[]) => {
    let vendors = vendorDataIn ?? [...vendorData];

    if (vendorIdIn) {
      vendors = vendors.filter((vendor: { [key: string]: any }) => vendor.vendorId === vendorIdIn);
    }

    let newTableData: { [key: string]: any }[] = [];
    for (let vendor of vendors) {
      newTableData = newTableData.concat(vendor.trucks);
    }

    setSelectedVendorId(vendorIdIn);
    setTableData(newTableData);
  }


  const handleCreateTruck = () => {
    setCreateTruckMode(true);
    setEditTruckData({
      vendorId: selectedVendorId ?? vendorData[0].vendorId,
      tag: '',
    });
  }


  const handleClickEditTruck = (index: number) => {
    setEditTruckIndex(index);
    setEditTruckData(tableData[index]);
  }


  const handleEditTruck = (keyIn: string, valueIn: string | number) => {
    let editTruckDataCopy = JSON.parse(JSON.stringify(editTruckData));
    editTruckDataCopy[keyIn] = valueIn;
    setEditTruckData(editTruckDataCopy);
  }


  const handleSubmitTruck = async () => {
    if (editTruckData === undefined) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    setSaveTruckLoading(true);

    try {
      let response = await httpCall('POST', 'Truck', undefined, undefined, editTruckData);
      if (response.success === true && response.status === 201) {
        let tableDataCopy = tableData.slice();
        setTableData(tableDataCopy.concat(response.data));
        setCreateTruckMode(false);
        setEditTruckData(undefined);
      } else {
        presentToast('Failed to create truck.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setSaveTruckLoading(false);
  }


  const handleSaveTruck = async () => {
    if (editTruckData === undefined) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    setSaveTruckLoading(true);

    if (!Object.entries(editTruckData).some(([key, value]) => tableData[editTruckIndex][key] !== value)) {
      setEditTruckIndex(-1);
      setEditTruckData(undefined);
    } else {
      try {
        let response = await httpCall('PUT', 'Truck', undefined, editTruckData.truckId, editTruckData);
        if (response.success === true && response.status === 204) {
          let tableDataCopy = tableData.slice();
          tableDataCopy[editTruckIndex] = editTruckData;
          setTableData(tableDataCopy);
          setEditTruckIndex(-1);
          setEditTruckData(undefined);
        } else {
          presentToast('Failed to save truck data', 'badToast');
        }
      } catch (e) {
        console.log(e);
        presentToast('An error occurred', 'badToast');
      }
    }

    setSaveTruckLoading(false);
  }


  const handleDeleteTruck = (indexIn: number) => {
    presentAlert({
      header: `Are you sure you want to delete the truck ${tableData[indexIn].tag ?? '-'}?`,
      buttons: [
        { text: 'Cancel', role: 'cancel', },
        {
          text: 'Delete', role: 'confirm', handler: async () => {
            try {
              if (toggleTruckIndex !== -1) return;

              setToggleTruckIndex(indexIn);

              let response = await httpCall('DELETE', 'Truck', undefined, tableData[indexIn].truckId);
              if (response.success === true && response.status === 204) {
                let tableDataCopy = tableData.slice();
                tableDataCopy.splice(indexIn, 1);
                setTableData(tableDataCopy);
              } else {
                presentToast('Failed to delete truck.', 'badToast');
              }
            } catch (e) {
              console.log(e);
              presentToast('An error occurred.', 'badToast');
            }

            setToggleTruckIndex(-1);
          },
        },
      ],
    });
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Manage Trucks</span>

                <div className='defaultSubsection defaultMarginTop'>
                  <SelectInput
                    sections={[{
                      options: {
                        'All': undefined,
                        ...Object.fromEntries(vendorData.map(vendor => [vendor.name, vendor.vendorId])),
                      }
                    }]}
                    value={selectedVendorId}
                    setValue={filterTableData}
                  />
                  <input className='textInput' value={searchTerm} onChange={(event: React.FocusEvent<HTMLInputElement>) => setSearchTerm(event.target.value)} autoComplete='off' spellCheck='false' type='text' placeholder='Search Tags' maxLength={50} />

                  <div className='smallButton marginLeftAuto' onClick={handleCreateTruck}>
                    <span>New Truck</span>
                  </div>
                </div>

                {
                  tableData.length === 0 ? <span className='defaultMediumText defaultMarginTop'>There are no trucks for this vendor.</span> : <PaginationTable
                    cssClasses='defaultMarginTop'
                    labels={['Vendor', 'Tag']}
                    elements={
                      tableData
                        .filter((truck: { [key: string]: any }) =>
                          Object.values(truck).some(value => (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())))
                        )
                        .map((truck: { [key: string]: any }, index: number) => [
                          <span className='defaultMediumText defaultTableElement'>{vendorData.find((vendor: { [key: string]: any }) => vendor.vendorId === truck.vendorId)?.name ?? '-'}</span>,
                          <span className='defaultMediumText defaultTableElement'>{truck.tag}</span>,
                          <div className='defaultTableButtonWrapper defaultTableElement'>
                            <div className='defaultTableButton' onClick={() => handleClickEditTruck(index)}>
                              <MdOutlineModeEdit className='defaultTableButtonIcon' />
                              <span className='defaultMediumText'>Edit</span>
                            </div>
                            <div className='defaultTableButton' onClick={() => handleDeleteTruck(index)}>
                              {
                                toggleTruckIndex === index ? <IonSpinner name='crescent' /> : <>
                                  <IoMdCloseCircleOutline className='defaultTableButtonIcon' />
                                  <span className='defaultMediumText'>Delete</span>
                                </>
                              }
                            </div>
                          </div>,
                        ])
                    }
                  />
                }
              </>
          }
        </div>

        {
          (editTruckIndex !== -1 && editTruckData) ?
            <div className='popupWrapper' onClick={() => { setEditTruckIndex(-1); setEditTruckData(undefined); }}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>Truck: {editTruckData.tag}</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop'>
                  <span className='defaultMediumText'>Vendor:</span>
                  <SelectInput
                    sections={[{ options: Object.fromEntries(vendorData.map(vendor => [vendor.name, vendor.vendorId])) }]}
                    value={editTruckData.vendorId}
                    setValue={(value: number) => handleEditTruck('vendorId', value)}
                  />

                  <span className='defaultMediumText'>Tag:</span>
                  <input className='textInput' type='text' placeholder='Tag' value={editTruckData.tag} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditTruck('tag', e.target.value)} />
                </div>

                <div className='smallButtonWrapper defaultMarginTop'>
                  <div className='smallButton defaultMarginTop' onClick={() => { setEditTruckIndex(-1); setEditTruckData(undefined); }}>
                    <span>Cancel</span>
                  </div>
                  <div className='smallButton defaultMarginTop' onClick={handleSaveTruck}>
                    {saveTruckLoading ? <IonSpinner name='crescent' /> : <span>Save</span>}
                  </div>
                </div>
              </div>
            </div> : null
        }

        {
          (createTruckMode && editTruckData) ?
            <div className='popupWrapper' onClick={() => { setCreateTruckMode(false); setEditTruckData(undefined); }}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>New Truck</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop'>
                  <span className='defaultMediumText'>Vendor:</span>
                  <SelectInput
                    sections={[{ options: Object.fromEntries(vendorData.map(vendor => [vendor.name, vendor.vendorId])) }]}
                    value={editTruckData.vendorId}
                    setValue={(value: number) => handleEditTruck('vendorId', value)}
                  />

                  <span className='defaultMediumText'>Tag:</span>
                  <input className='textInput' type='text' placeholder='Tag' value={editTruckData.tag} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEditTruck('tag', e.target.value)} />
                </div>

                <div className='smallButtonWrapper mediumMarginTop'>
                  <div className='smallButton' onClick={() => { setCreateTruckMode(false); setEditTruckData(undefined); }}>
                    <span>Cancel</span>
                  </div>
                  <div className='smallButton' onClick={handleSubmitTruck}>
                    {saveTruckLoading ? <IonSpinner name='crescent' /> : <span>Add</span>}
                  </div>
                </div>
              </div>
            </div> : null
        }
      </IonContent>
    </IonPage>
  );
};

export default ManageTrucks;
