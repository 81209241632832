import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../components/Header';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './ViewPump.css';

interface ViewPumpParams {
  pumpId: string;
}

const ViewPump: React.FC = () => {

  const { pumpId } = useParams<ViewPumpParams>();
  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [pumpData, setPumpData] = useState<any>();

  const [downloading, setDownloading] = useState<boolean>(false);


  useEffect(() => {
    if (result !== null) initViewPump();
  }, [result]);


  const initViewPump = async () => {
    try {
      let response = await httpCall('GET', 'Pumpactivity', undefined, pumpId);
      if (response.success === true && response.status === 200) {
        if (response.data.gwh?.attributes) {
          if (response.data.gwh.attributes.firstName === 'Detached user') {
            response.data.gwh.attributes.fullName = response.data.gwh.attributes.firstName;
          } else if (response.data.gwh.attributes.firstName && response.data.gwh.attributes.lastName) {
            response.data.gwh.attributes.fullName = `${response.data.gwh.attributes.firstName} ${response.data.gwh.attributes.lastName}`;
          } else {
            response.data.gwh.attributes.fullName = response.data.gwh.attributes.firstName ?? response.data.gwh.attributes.lastName ?? '-';
          }
        }

        setPumpData(response.data);
      } else {
        presentToast('Error getting pump activity data.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setLoading(false);
  }


  const handleDownloadReport = async () => {
    if (downloading) return;

    setDownloading(true);

    try {
      let response = await httpCall('GET', 'Report', `pump?id=${pumpId}`, undefined, undefined, true);
      if (response.success === true && response.status === 200) {
        let url = URL.createObjectURL(response.data);
        let anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Pumpactivity_Report.pdf'; //TODO: couldn't extract name from content-disposition header...
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      } else {
        presentToast('Failed to generate report.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Error generating report.', 'badToast');
    }

    setDownloading(false);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              pumpData ?
                <>
                  <span className='defaultLargeText'>Pump Details</span>

                  <div className='defaultSection defaultMarginTop'>
                    <span className='defaultMediumText'><span className='boldText'>Generator Name:</span> {pumpData.fse?.name}</span>

                    <span className='defaultMediumText'><span className='boldText'>Phone:</span> {pumpData.fse?.phoneNumber}</span>

                    <span className='defaultMediumText'><span className='boldText'>Address:</span> {pumpData.fse?.address}</span>

                    <span className='defaultMediumText'><span className='boldText'>Grease Waste Hauler Name:</span> {pumpData.gwh?.attributes?.fullName}</span>

                    <span className='defaultMediumText'><span className='boldText'>Grease Waste Hauler Vendor:</span> {pumpData.gwh?.vendor?.name ?? 'N/A'}</span>

                    <span className='defaultMediumText'><span className='boldText'>Grease Waste Hauler Truck:</span> {pumpData.truckTag ?? 'N/A'}</span>

                    <span className='defaultMediumText'><span className='boldText'>Date and Time:</span> {new Date(pumpData.datePumped).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true })}</span>

                    <span className='defaultMediumText'><span className='boldText'>Gallons Pumped:</span> {pumpData.greaseGal}</span>

                    <span className='defaultMediumText'><span className='boldText'>Percent Solid FOG:</span> {`${pumpData.pctSolids?.trim()}%`}</span>

                    <span className='defaultMediumText'><span className='boldText'>GCE has issues:</span> {pumpData.gceIssue === 0 ? 'No' : 'Yes'}</span>

                    <span className='defaultMediumText'><span className='boldText'>Comments:</span> {pumpData.gceIssueComment ?? 'None'}</span>

                    <span className='defaultMediumText'><span className='boldText'>Disposed:</span> {pumpData.disposalId ? 'Yes - ' : 'No'}{pumpData.disposalId ? <span className='linkText' onClick={() => history.push(`/viewdisposal/${pumpData.disposalId}`)}>View</span> : null}</span>
                  </div>

                  <div className='smallButtonWrapper mediumMarginTop'>
                    <div className='smallButton' onClick={() => history.goBack()}>
                      <span>Back</span>
                    </div>
                    <div className='smallButton' onClick={handleDownloadReport}>
                      {downloading ? <IonSpinner name='crescent' /> : <span>Download</span>}
                    </div>
                  </div>
                </> : null
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ViewPump;
