import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useState } from 'react';
import { loginRequest } from '../utils/authConfig';
import { useCustomToast } from '../utils/toast';
import './Login.css';

const Login: React.FC = () => {

  const { instance } = useMsal();
  const presentToast = useCustomToast();

  const [loginProcessing, setLoginProcessing] = useState<boolean>(false);


  const handleLogin = async () => {
    if (loginProcessing) return;

    setLoginProcessing(true);

    try {
      await instance.loginRedirect(loginRequest);
    } catch (e) {
      console.log(e);
      presentToast('Failed to log in.', 'badToast');
    }

    setLoginProcessing(false);
  }


  return (
    <IonPage>
      <IonContent>
        <div className='pageContent pageContentNoHeader'>
          <div className='bodyLogoWrapper defaultMarginSides'>
            <img className='bodyLogo' src='/logo192.png' alt='B' />
            <span className='bodyLogoSpan'>FATS</span>
          </div>

          <span className='defaultLargeText largeMarginTop defaultMarginSides'>FOG Activity Tracking System</span>

          <span className='defaultMediumText mediumMarginTop defaultMarginSides'>The FATS app is a web-based application that enables an organization (i.e. a city) to track inspections of grease containment equipment, FOG (fats, oils, and grease) pumping activities, and disposals of FOG at specific treatment facilities. The app makes it easier and more efficient to implement a FOG program to improve the operation of wastewater collection systems and proactively prevent corrective or reactive maintenance.</span>

          <div className='loginInputWrapper defaultMarginSides'>
            {/* <input className='textInput largeMarginTop' onChange={(event: React.FocusEvent<HTMLInputElement>) => onInputChange('email', event.target.value)} value={email} autoComplete='off' spellCheck='false' type='text' placeholder='Email' maxLength={50}></input>
            <input className='textInput defaultMarginTop' onChange={(event: React.FocusEvent<HTMLInputElement>) => onInputChange('password', event.target.value)} value={password} autoComplete='off' spellCheck='false' type='password' placeholder='Password' maxLength={50}></input> */}

            <div className='largeButton largeMarginTop' onClick={handleLogin}>
              {loginProcessing ? <IonSpinner name='crescent' /> : <span>Login</span>}
            </div>

            {/* <span className='loginForgotPasswordSpan defaultMediumText linkText' onClick={handleForgotPassword}>Forgot password?</span>

            <div className='defaultDivider mediumMarginTop'></div>

            <div className='largeButton mediumMarginTop' onClick={handleLogin}>
              <span>FSE Login</span>
            </div> */}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
