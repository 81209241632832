import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdOutlineModeEdit } from 'react-icons/md';
import { WiCloudRefresh } from 'react-icons/wi';
import Header from '../components/Header';
import PaginationTable from '../components/PaginationTable';
import SelectInput from '../components/SelectInput';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './ManageUsers.css';

const ManageUsers: React.FC = () => {

  const { instance } = useMsal();
  const [presentAlert] = useIonAlert();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const vendorRoleIds = [5, 8];

  const [loading, setLoading] = useState<boolean>(true);

  const [userRole, setUserRole] = useState<number>(0);

  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [orgData, setOrgData] = useState<{ [key: string]: any }[]>([]);
  const [roleData, setRoleData] = useState<{ [key: string]: any }[]>([]);
  const [vendorData, setVendorData] = useState<{ [key: string]: any }[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [editUserIndex, setEditUserIndex] = useState<number>(-1);
  const [editUserData, setEditUserData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [saveUserLoading, setSaveUserLoading] = useState<boolean>(false);

  const [toggleUserIndex, setToggleUserIndex] = useState<number>(-1);


  useEffect(() => {
    if (result !== null) initManageUsers();
  }, [result]);


  const initManageUsers = async () => {
    try {
      let accountClaims = instance.getActiveAccount()?.idTokenClaims;
      let roleId = accountClaims?.extension_APPRole;
      if (typeof roleId === 'number') { //this is so dumb, extension_APPRole is always a number and never {}, but it says its {}....
        setUserRole(roleId);

        let [userResponse, orgResponse, roleResponse, vendorResponse] = await Promise.all([
          httpCall('GET', 'User', 'all?onlyActive=false'),
          httpCall('GET', 'Org'),
          httpCall('GET', 'Role'),
          httpCall('GET', 'Vendor'),
        ]);

        if (userResponse.success === true && userResponse.status === 200) {
          for (let user of userResponse.data) {
            if (user.firstName === 'Detached user') {
              user.fullName = user.firstName;
            } else if (user.firstName && user.lastName) {
              user.fullName = `${user.firstName} ${user.lastName}`;
            } else {
              user.fullName = user.firstName ?? user.lastName ?? '-';
            }
          }
          setTableData(userResponse.data);
        } else {
          presentToast('Error getting users.', 'badToast');
        }

        if (orgResponse.success === true && orgResponse.status === 200) {
          setOrgData(orgResponse.data);
        } else {
          presentToast('Error getting organizations.', 'badToast');
        }

        if (roleResponse.success === true && roleResponse.status === 200) {
          setRoleData(roleResponse.data);
        } else {
          presentToast('Error getting user roles.', 'badToast');
        }

        if (vendorResponse.success === true && vendorResponse.status === 200) {
          setVendorData(vendorResponse.data);
        } else {
          presentToast('Error getting vendors.', 'badToast');
        }
      } else {
        presentToast('A token error occurred. Please log out and try again.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    } finally {
      setLoading(false);
    }
  }


  const handleClickEditUser = (index: number) => {
    setEditUserIndex(index);
    setEditUserData({ ...tableData[index], vendorID: (tableData[index].vendorID === null ? -1 : tableData[index].vendorID) });
  }


  const handleEditUser = (keyIn: string, valueIn: number) => {
    let editUserDataCopy = JSON.parse(JSON.stringify(editUserData));

    editUserDataCopy[keyIn] = valueIn;

    if (keyIn === 'firstName' || keyIn === 'lastName') {
      if (editUserDataCopy.firstName && editUserDataCopy.lastName) {
        editUserDataCopy.fullName = `${editUserDataCopy.firstName} ${editUserDataCopy.lastName}`;
      } else {
        editUserDataCopy.fullName = editUserDataCopy.firstName ?? editUserDataCopy.lastName ?? '-';
      }
    } else if (keyIn === 'roleID') {
      if (vendorRoleIds.includes(valueIn) && editUserDataCopy.vendorID === null) {
        editUserDataCopy.vendorID = -1;
      } else if (!vendorRoleIds.includes(valueIn)) {
        editUserDataCopy.vendorID = null;
      }
    }

    setEditUserData(editUserDataCopy);
  }


  const handleSaveUser = async () => {
    if (editUserData === undefined) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    setSaveUserLoading(true);

    let preppedInviteData: { [key: string]: any } = { ...editUserData, vendorId: (editUserData.vendorId === -1 ? null : editUserData.vendorId) };

    if (!Object.entries(preppedInviteData).some(([key, value]) => (key !== 'fullName' && tableData[editUserIndex][key] !== value))) {
      setEditUserIndex(-1);
      setEditUserData(undefined);
    } else {
      try {
        let { userID, fullName, ...userData } = preppedInviteData;
        let response = await httpCall('PUT', 'User', undefined, userID, userData);
        if (response.success === true && response.status === 200) {
          let tableDataCopy = tableData.slice();
          tableDataCopy[editUserIndex] = preppedInviteData;
          setTableData(tableDataCopy);
          setEditUserIndex(-1);
          setEditUserData(undefined);
        } else {
          presentToast('Failed to save user\'s data', 'badToast');
        }
      } catch (e) {
        console.log(e);
        presentToast('An error occurred', 'badToast');
      }
    }

    setSaveUserLoading(false);
  }


  const handleDisableUser = (indexIn: number) => {
    presentAlert({
      header: `Are you sure you want to disable the account of ${tableData[indexIn].fullName === '-' ? 'this user' : tableData[indexIn].fullName}?`,
      buttons: [
        { text: 'Cancel', role: 'cancel', },
        {
          text: 'Disable', role: 'confirm', handler: async () => {
            try {
              if (toggleUserIndex !== -1) return;

              setToggleUserIndex(indexIn);

              let response = await httpCall('PUT', 'User', 'deactivate', tableData[indexIn].userID);
              if (response.success === true && response.status === 200) {
                let tableDataCopy = tableData.slice();
                tableDataCopy[indexIn].isActive = 0;
                setTableData(tableDataCopy);
              } else {
                presentToast('Failed to disable user', 'badToast');
              }
            } catch (e) {
              console.log(e);
              presentToast('An error occurred', 'badToast');
            }

            setToggleUserIndex(-1);
          },
        },
      ],
    });
  }


  const handleEnableUser = (indexIn: number) => {
    presentAlert({
      header: `Are you sure you want to enable the account of ${tableData[indexIn].fullName === '-' ? 'this user' : tableData[indexIn].fullName}?`,
      buttons: [
        { text: 'Cancel', role: 'cancel', },
        {
          text: 'Enable', role: 'confirm', handler: async () => {
            try {
              if (toggleUserIndex !== -1) return;

              setToggleUserIndex(indexIn);

              let response = await httpCall('PUT', 'User', 'activate', tableData[indexIn].userID);
              if (response.success === true && response.status === 200) {
                let tableDataCopy = tableData.slice();
                tableDataCopy[indexIn].isActive = 1;
                setTableData(tableDataCopy);
              } else {
                presentToast('Failed to enable user', 'badToast');
              }
            } catch (e) {
              console.log(e);
              presentToast('An error occurred', 'badToast');
            }

            setToggleUserIndex(-1);
          },
        },
      ],
    });
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Manage Users</span>

                <div className='defaultSubsection defaultMarginTop'>
                  <input className='textInput' value={searchTerm} onChange={(event: React.FocusEvent<HTMLInputElement>) => setSearchTerm(event.target.value)} autoComplete='off' spellCheck='false' type='text' placeholder='Search User' maxLength={50} />
                </div>

                {
                  tableData.length === 0 ? null : <PaginationTable
                    cssClasses='defaultMarginTop'
                    labels={['Name', 'Email', 'Organization', 'Role', 'Vendor']}
                    elements={
                      tableData
                        .filter((user: { [key: string]: any }) =>
                          Object.values(user).some(value => (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())))
                          || orgData.find((org: { [key: string]: any }) => org.orgId === user.orgID)?.orgName.toLowerCase().includes(searchTerm.toLowerCase())
                          || roleData.find((role: { [key: string]: any }) => role.roleId === user.roleID)?.roleName.toLowerCase().includes(searchTerm.toLowerCase())
                          || vendorData.find((vendor: { [key: string]: any }) => vendor.vendorId === user.vendorID)?.name.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((user: { [key: string]: any }, index: number) => [
                          <span className={`defaultMediumText defaultTableElement${user.isActive ? '' : ' defaultTableElementDisabled'}`}>{user.fullName}</span>,
                          <span className={`defaultMediumText defaultTableElement${user.isActive ? '' : ' defaultTableElementDisabled'}`}>{user.email ?? '-'}</span>,
                          <span className={`defaultMediumText defaultTableElement${user.isActive ? '' : ' defaultTableElementDisabled'}`}>{orgData.find((org: { [key: string]: any }) => org.orgId === user.orgID)?.orgName ?? '-'}</span>,
                          <span className={`defaultMediumText defaultTableElement${user.isActive ? '' : ' defaultTableElementDisabled'}`}>{roleData.find((role: { [key: string]: any }) => role.roleId === user.roleID)?.roleName ?? '-'}</span>,
                          <span className={`defaultMediumText defaultTableElement${user.isActive ? '' : ' defaultTableElementDisabled'}`}>{vendorData.find((vendor: { [key: string]: any }) => vendor.vendorId === user.vendorID)?.name ?? 'N/A'}</span>,
                          <div className={`defaultTableButtonWrapper defaultTableElement${user.isActive ? '' : ' defaultTableElementDisabled'}`}>
                            <div className='defaultTableButton' onClick={() => handleClickEditUser(index)}>
                              <MdOutlineModeEdit className='defaultTableButtonIcon' />
                              <span className='defaultMediumText'>Edit</span>
                            </div>
                            {
                              user.isActive ?
                                <div className='defaultTableButton' onClick={() => handleDisableUser(index)}>
                                  {
                                    toggleUserIndex === index ? <IonSpinner name='crescent' /> : <>
                                      <IoMdCloseCircleOutline className='defaultTableButtonIcon' />
                                      <span className='defaultMediumText'>Disable</span>
                                    </>
                                  }
                                </div>
                                :
                                <div className='defaultTableButton' onClick={() => handleEnableUser(index)}>
                                  {
                                    toggleUserIndex === index ? <IonSpinner name='crescent' /> : <>
                                      <WiCloudRefresh className='defaultTableButtonIconStupid' />
                                      <span className='defaultMediumText'>Enable</span>
                                    </>
                                  }
                                </div>
                            }
                          </div>,
                        ])
                    }
                  />
                }
              </>
          }
        </div>

        {
          (!editUserData || editUserIndex === -1) ? null :
            <div className='popupWrapper' onClick={() => { setEditUserIndex(-1); setEditUserData(undefined); }}>
              <div className='popupContent' onClick={(e) => e.stopPropagation()}>
                <span className='defaultLargeText'>User: {editUserData.fullName}</span>

                <div className='defaultEditGrid defaultSection defaultMarginTop'>
                  <span className='defaultMediumText'>Email:</span>
                  <span className='defaultMediumText'>{editUserData.email}</span>

                  <span className='defaultMediumText'>Organization:</span>
                  <span className='defaultMediumText'>{orgData.find((org: { [key: string]: any }) => org.orgId === editUserData.orgID)?.orgName ?? '-'}</span>

                  <span className='defaultMediumText'>Role:</span>
                  {
                    (userRole !== 1 && editUserData.roleID === 1) ? <span className='defaultMediumText'>{roleData.find((role: { [key: string]: any }) => role.roleId === userRole)?.roleName ?? '-'}</span> :
                      <SelectInput
                        sections={[{ options: Object.fromEntries(roleData.filter(role => !(userRole !== 1 && role.roleId === 1)).map(role => [role.roleName, role.roleId])) }]}
                        value={editUserData.roleID}
                        setValue={(value: number) => handleEditUser('roleID', value)}
                      />
                  }

                  {
                    !vendorRoleIds.includes(editUserData.roleID) ? null : <>
                      <span className='defaultMediumText'>Vendor:</span>
                      <SelectInput
                        sections={[{
                          options: {
                            'N/A': -1,
                            ...Object.fromEntries(vendorData.map(vendor => [vendor.name, vendor.vendorId])),
                          }
                        }]}
                        value={editUserData.vendorID ?? -1}
                        setValue={(value: number) => handleEditUser('vendorID', value)}
                      />
                    </>
                  }
                </div>

                <div className='smallButtonWrapper defaultMarginTop'>
                  <div className='smallButton defaultMarginTop' onClick={() => { setEditUserIndex(-1); setEditUserData(undefined); }}>
                    <span>Back</span>
                  </div>
                  <div className='smallButton defaultMarginTop' onClick={handleSaveUser}>
                    {saveUserLoading ? <IonSpinner name='crescent' /> : <span>Save</span>}
                  </div>
                </div>
              </div>
            </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default ManageUsers;
