import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import PaginationTable from '../components/PaginationTable';
import SelectInput from '../components/SelectInput';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './FatsDispose.css';

const FatsDispose: React.FC = () => {

  const { instance } = useMsal();
  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [wwtpId, setWwtpId] = useState<number>(1);

  const [pumpActivities, setPumpActivities] = useState<any[]>(); //TODO: make this an object that maps pumpActivityId to pump
  const [selectedPumpActivities, setSelectedPumpActivities] = useState<any[]>([]); //TODO: make this an array of pumpActivityId
  const [selectPumpsMode, setSelectPumpsMode] = useState<boolean>(false);

  const [date, setDate] = useState<any>(new Date());

  const [submitDisposeLoading, setSubmitDisposeLoading] = useState<boolean>(false);


  useEffect(() => {
    if (result !== null) initFatsDispose();
  }, [result]);


  const initFatsDispose = async () => {
    let gwhSub = instance.getActiveAccount()?.idTokenClaims?.sub;
    if (gwhSub) {
      try {
        let response = await httpCall('GET', `Pumpactivity?disposed=false`);
        if (response.success === true && response.status === 200) {
          setPumpActivities(response.data);
        } else {
          presentToast('Could not load your pump activity.', 'badToast');
        }
      } catch (e) {
        console.log(e);
        presentToast('Error loading your pump activity.', 'badToast');
      }
    } else {
      presentToast('An error occurred. Please log out and try again.', 'badToast');
    }

    setLoading(false);
  }


  const handleSubmitDisposal = async () => {
    if (submitDisposeLoading) return;

    if (!pumpActivities) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    if (selectedPumpActivities.length === 0) {
      presentToast('Please add pump activity to the disposal.', 'badToast');
      return;
    };

    setSubmitDisposeLoading(true);

    try {
      let response = await httpCall('POST', 'Disposal', undefined, undefined, {
        pumpActivityIds: selectedPumpActivities.map((pump: any) => pump.pumpActivityId),
        disposalDate: date.toISOString(),
        wwtpId: wwtpId,
      });

      if (response.success === true && response.status === 200) {
        presentToast('Disposal activity saved.', 'goodToast');
        history.push('/fats');
      } else {
        presentToast('Failed to save Disposal activity.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Failed to save Disposal activity.', 'badToast');
    }

    setSubmitDisposeLoading(false);
  }


  const generateDateTimeString = (dateTimeString: string): string => {
    let date = new Date(dateTimeString);
    return `${date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })} ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Disposal Activity Report</span>

                <div className='defaultSection defaultMarginTop'>
                  <div className='defaultEditGrid'>
                    <span className='defaultMediumText boldText'>Location:</span>
                    <SelectInput
                      sections={[{ options: { 'Omussee Wastewater Treatment Plant': 1, 'Other': 2 } }]}
                      value={wwtpId}
                      setValue={(value: string) => setWwtpId(Number(value))}
                    />

                    <span className='defaultMediumText boldText'>Date and Time:</span>
                    <div>
                      <DatePicker className='textInput' selected={date} onChange={(d: any) => setDate(d)} showTimeSelect />
                    </div>
                  </div>
                </div>

                {
                  pumpActivities ?
                    <div className='defaultSectionBot'>
                      <span className='defaultMediumText boldText'>Selected Pump Activity</span>
                      {
                        selectedPumpActivities.length === 0 ? <span className='defaultMediumText'>There is no pump activity associated with this disposal.</span> :
                          <PaginationTable
                            labels={['Establishment', 'Address', 'Amount Pumped (gal)', 'Date Pumped']}
                            elements={
                              selectedPumpActivities.map((pump: any) => [
                                <span className='defaultMediumText defaultTableElement'>{pump.fse.name}</span>,
                                <span className='defaultMediumText defaultTableElement'>{pump.fse.location}</span>,
                                <span className='defaultMediumText defaultTableElement'>{pump.greaseGal}</span>,
                                <span className='defaultMediumText defaultTableElement'>{generateDateTimeString(pump.datePumped)}</span>,
                                <div className='defaultMediumText defaultTableElement defaultTableButtonWrapper'>
                                  <div className='defaultTableButton' onClick={() => setSelectedPumpActivities((currentSelected: any[]) => currentSelected.filter(p => p !== pump))}>
                                    <IoMdCloseCircleOutline className='defaultTableButtonIcon' />
                                    <span className='defaultMediumText'>Remove</span>
                                  </div>
                                </div>,
                              ])
                            }
                          />
                      }

                      {
                        selectPumpsMode ? <>
                          <span className='defaultMediumText boldText mediumMarginTop'>Add Pump Activity</span>
                          {
                            pumpActivities.filter((pump: any) => !selectedPumpActivities.includes(pump)).length === 0 ? <span className='defaultMediumText'>No undisposed pump activity found.</span> :
                              <PaginationTable
                                labels={['Establishment', 'Address', 'Amount Pumped (gal)', 'Date Pumped']}
                                elements={
                                  pumpActivities
                                    .filter((pump: any) => !selectedPumpActivities.includes(pump))
                                    .map((pump: any) => [
                                      <span className='defaultMediumText defaultTableElement defaultTableElementDisabled'>{pump.fse.name}</span>,
                                      <span className='defaultMediumText defaultTableElement defaultTableElementDisabled'>{pump.fse.location}</span>,
                                      <span className='defaultMediumText defaultTableElement defaultTableElementDisabled'>{pump.greaseGal}</span>,
                                      <span className='defaultMediumText defaultTableElement defaultTableElementDisabled'>{generateDateTimeString(pump.datePumped)}</span>,
                                      <div className='defaultMediumText defaultTableElement defaultTableElementDisabled defaultTableButtonWrapper'>
                                        <div className='defaultTableButton' onClick={() => setSelectedPumpActivities((currentSelected: any[]) => currentSelected.concat(pump))}>
                                          <IoMdAddCircleOutline className='defaultTableButtonIcon' />
                                          <span className='defaultMediumText'>Add</span>
                                        </div>
                                      </div>,
                                    ])
                                }
                              />
                          }
                        </> : null
                      }

                      <div className='smallButton' onClick={() => setSelectPumpsMode(!selectPumpsMode)}>
                        <span>{selectPumpsMode ? 'Done' : 'Add'}</span>
                      </div>
                    </div> : null
                }


                <div className='smallButtonWrapper mediumMarginTop'>
                  <div className='smallButton' onClick={() => history.goBack()}>
                    <span>Cancel</span>
                  </div>
                  {
                    selectedPumpActivities.length > 0 ?
                      <div className='smallButton' onClick={handleSubmitDisposal}>
                        {submitDisposeLoading ? <IonSpinner name='crescent' /> : <span>Submit</span>}
                      </div> : null
                  }
                </div>
              </>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FatsDispose;
