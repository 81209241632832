import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import SelectInput from '../components/SelectInput';
import ToggleSwitch from '../components/ToggleSwitch';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './FatsPump.css';

const FatsPump: React.FC = () => {

  const { instance } = useMsal();
  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [userName, setUserName] = useState<any>();

  const [locationSearchTerm, setLocationSearchTerm] = useState<string>('');
  const [prevSearchTerm, setPrevSearchTerm] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const [potentialLocations, setPotentialLocations] = useState<any[]>([]);
  const [location, setLocation] = useState<any>();

  const [potentialTrucks, setPotentialTrucks] = useState<{ [key: string]: any }[]>([]);
  const [truckTag, setTruckTag] = useState<string>();

  const [date, setDate] = useState<any>(new Date());
  const [gallonsPumped, setGallonsPumped] = useState<number>();
  const [percentFogSolid, setPercentFogSolid] = useState<number>();
  const [noIssue, setNoIssue] = useState<boolean>(true);

  const [comments, setComments] = useState<string>('');

  const [submitPumpLoading, setSubmitPumpLoading] = useState<boolean>(false);


  useEffect(() => {
    if (result !== null) initFatsPump();
  }, [result]);


  const initFatsPump = async () => {
    try {
      let gwhAccount = instance.getActiveAccount();
      if (gwhAccount?.idTokenClaims) {
        setUserName(gwhAccount.idTokenClaims.name ?? gwhAccount.name ?? ((gwhAccount.idTokenClaims.emails && gwhAccount.idTokenClaims.emails.length > 0) ? gwhAccount.idTokenClaims.emails[0] : undefined) ?? gwhAccount.username);

        let response = await httpCall('GET', 'Truck');
        if (response.success === true && response.status === 200) {
          setPotentialTrucks(response.data);
        } else {
          presentToast('Could not load trucks.', 'badToast');
        }
      } else {
        presentToast('An error occurred. Please log out and try again.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Error loading trucks.', 'badToast');
    }

    setLoading(false);
  }


  const handleFseSearch = async () => {
    if (searchLoading) return;
    setSearchLoading(true);

    try {
      let response = await httpCall('GET', `ArcGIS/searchfse?query=${locationSearchTerm}`);
      if (response.success === true && response.status === 200) {
        setPrevSearchTerm(locationSearchTerm);
        setPotentialLocations(response.data);
      } else {
        presentToast('Failed to search FSEs.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setSearchLoading(false);
    setHasSearched(true);
  }


  const handleSubmitPump = async () => {
    if (submitPumpLoading) return;

    if (!location?.assetId) {
      presentToast('An error occurred.', 'badToast');
      return;
    };

    if (!truckTag) {
      presentToast('A truck tag is required.', 'badToast');
      return;
    };

    if (!gallonsPumped || !percentFogSolid) {
      presentToast('Please fill out required fields.', 'badToast');
      return;
    };

    setSubmitPumpLoading(true);

    try {
      let response = await httpCall('POST', 'Pumpactivity', undefined, undefined, {
        fseGISID: location.assetId,
        truckTag: truckTag,
        grease_gal: gallonsPumped,
        pct_total_solids: String(percentFogSolid),
        datePumped: date.toISOString(),
        gceIssue: (noIssue ? 0 : 1),
        gceIssueComment: (comments !== '' ? comments : null),
      });

      if (response.success === true && response.status === 201) {
        presentToast('Pump activity saved.', 'goodToast');
        history.push('/fats');
      } else {
        presentToast('Failed to save pump activity.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Failed to save pump activity.', 'badToast');
    }

    setSubmitPumpLoading(false);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Pump Activity Report</span>

                {
                  location ? <>
                    <div className='defaultSection defaultMarginTop'>
                      <span className='defaultMediumText'>Generator Name: {location.name}</span>

                      <span className='defaultMediumText'>Phone: {location.phoneNumber}</span>

                      <span className='defaultMediumText'>Address: {location.address}</span>

                      {/* <span className='defaultMediumText'>City: {location.city}</span> */}

                      {/* <span className='defaultMediumText'>Zip: {location.Zip}</span> */}
                    </div>

                    <div className='defaultSectionBot'>
                      <div className='defaultEditGrid'>
                        <span className='defaultMediumText'>Grease Waste Hauler:</span>
                        <span className='defaultMediumText'>{userName}</span>

                        <span className='defaultMediumText boldText'>Truck Tag:</span>
                        {
                          potentialTrucks.length === 0 ? <span className='defaultMediumText'>No trucks found for your vendor.</span> :
                            <SelectInput
                              sections={[{ options: Object.fromEntries(potentialTrucks.map((truck: { [key: string]: any; }) => [truck.tag, truck.tag])) }]}
                              value={truckTag}
                              setValue={setTruckTag}
                              placeholder='Select truck tag'
                            />
                        }

                        <span className='defaultMediumText boldText'>Date and Time:</span>
                        <div>
                          <DatePicker className='textInput' selected={date} onChange={(d: any) => setDate(d)} showTimeSelect />
                        </div>

                        <span className='defaultMediumText boldText'>Gallons Pumped:</span>
                        <input className='textInput inputShort' type='number' value={gallonsPumped} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGallonsPumped(parseFloat(e.target.value))} />

                        <span className='defaultMediumText boldText'>Percent Solid FOG:</span>
                        <input className='textInput inputShort' type='number' value={percentFogSolid} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPercentFogSolid(parseFloat(e.target.value))} />

                        <span className='defaultMediumText boldText'>The GCE has no issues:</span>
                        <ToggleSwitch toggleId='noIssue' isOn={noIssue} handleToggle={setNoIssue} />
                      </div>

                      {
                        noIssue ? null : <>
                          <span className='defaultMediumText boldText'>Comments:</span>
                          <textarea className='textArea' value={comments} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComments(e.target.value)} />
                        </>
                      }
                    </div>
                  </> : (
                    <div className='defaultSection defaultMarginTop'>
                      <div className='defaultSubsection'>
                        <span className='defaultMediumText boldText'>Search for Location:</span>
                        <input className='textInput' type='text' value={locationSearchTerm} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocationSearchTerm(e.target.value)} />
                        {
                          (locationSearchTerm.length > 0 && locationSearchTerm !== prevSearchTerm) ?
                            <div className='smallButton' onClick={handleFseSearch}>
                              {searchLoading ? <IonSpinner name='crescent' /> : <span>Search</span>}
                            </div> : null
                        }
                      </div>

                      {
                        potentialLocations.length > 0 ?
                          <div className='defaultEditGrid'>
                            {
                              potentialLocations.map((l: any) => <Fragment key={l.assetId}>
                                <div className='defaultColumnWrapper'>
                                  <span className='defaultMediumText'>{l.name}</span>
                                  <span className='defaultSmallText'>{l.location}</span>
                                </div>
                                <div className='smallButton' onClick={() => setLocation(l)}>Select</div>
                              </Fragment>)
                            }
                          </div>
                          :
                          hasSearched ? <span className='defaultMediumText dangerText'>No results found.</span> : null
                      }
                    </div>
                  )
                }

                <div className='smallButtonWrapper mediumMarginTop'>
                  <div className='smallButton' onClick={() => location ? setLocation(undefined) : history.goBack()}>
                    <span>Cancel</span>
                  </div>
                  {
                    location ?
                      <div className='smallButton' onClick={handleSubmitPump}>
                        {submitPumpLoading ? <IonSpinner name='crescent' /> : <span>Submit</span>}
                      </div> : null
                  }
                </div>
              </>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FatsPump;
