import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { Chart, Filler, Legend, LineElement, LinearScale, PointElement, Tooltip } from 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './Dashboard.css';

Chart.register(
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const Dashboard: React.FC = () => {

  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [currentMonth, setCurrentMonth] = useState<string>();
  const [lastMonth, setLastMonth] = useState<string>();

  const [chartData, setChartData] = useState<any>();
  const [chartOptions, setChartOptions] = useState<any>();

  const [inspectionViewMode, setInspectionViewMode] = useState<boolean>(false);

  const [inspectionCount, setInspectionCount] = useState<number>();
  const [inspectionCountMonth, setInspectionCountMonth] = useState<number>();
  const [inspectionCountLastMonth, setInspectionCountLastMonth] = useState<number>();

  const [compliantInspections, setCompliantInspections] = useState<number>();
  const [compliantInspectionsMonth, setCompliantInspectionsMonth] = useState<number>();
  const [compliantInspectionsLastMonth, setCompliantInspectionsLastMonth] = useState<number>();

  const [failedInspections, setFailedInspections] = useState<number>();
  const [failedInspectionsMonth, setFailedInspectionsMonth] = useState<number>();
  const [failedInspectionsLastMonth, setFailedInspectionsLastMonth] = useState<number>();

  const [overdueInspections, setOverdueInspections] = useState<number>();
  const [overdueInspectionsMonth, setOverdueInspectionsMonth] = useState<number>();
  const [overdueInspectionsLastMonth, setOverdueInspectionsLastMonth] = useState<number>();

  const [pumpViewMode, setPumpViewMode] = useState<boolean>(false);

  const [pumpCount, setPumpCount] = useState<number>();
  const [pumpCountMonth, setPumpCountMonth] = useState<number>();
  const [pumpCountLastMonth, setPumpCountLastMonth] = useState<number>();

  const [pumpGallons, setPumpGallons] = useState<number>();
  const [pumpGallonsMonth, setPumpGallonsMonth] = useState<number>();
  const [pumpGallonsLastMonth, setPumpGallonsLastMonth] = useState<number>();

  const [overduePump, setOverduePump] = useState<number>();
  const [overduePumpMonth, setOverduePumpMonth] = useState<number>();
  const [overduePumpLastMonth, setOverduePumpLastMonth] = useState<number>();


  useEffect(() => {
    if (result !== null) initAdminDashboard();
  }, [result]);


  const initAdminDashboard = async () => {
    try {
      let [inspectionResponse, pumpResponse, disposalResponse] = await Promise.all([
        httpCall('GET', 'Inspection', 'stats'),
        httpCall('GET', 'Pumpactivity', 'stats'),
        httpCall('GET', 'Disposal', 'stats'),
      ]);

      if (inspectionResponse.success === true && inspectionResponse.status === 200) {
        setInspectionCount(inspectionResponse.data.inspectionCount);
        setInspectionCountMonth(inspectionResponse.data.inspectionCountMonth);
        setInspectionCountLastMonth(inspectionResponse.data.inspectionCountLastMonth);

        setCompliantInspections(inspectionResponse.data.compliantInspections);
        setCompliantInspectionsMonth(inspectionResponse.data.compliantInspectionsMonth);
        setCompliantInspectionsLastMonth(inspectionResponse.data.compliantInspectionsLastMonth);

        setFailedInspections(inspectionResponse.data.failedInspections);
        setFailedInspectionsMonth(inspectionResponse.data.failedInspectionsMonth);
        setFailedInspectionsLastMonth(inspectionResponse.data.failedInspectionsLastMonth);

        setOverdueInspections(inspectionResponse.data.overdueInspections);
        setOverdueInspectionsMonth(inspectionResponse.data.overdueInspectionsMonth);
        setOverdueInspectionsLastMonth(inspectionResponse.data.overdueInspectionsLastMonth);
      } else {
        presentToast('Could not load inspection activity.', 'badToast');
        setLoading(false);
        return;
      }

      if (pumpResponse.success === true && pumpResponse.status === 200) {
        setPumpCount(pumpResponse.data.pumpCount);
        setPumpCountMonth(pumpResponse.data.pumpCountMonth);
        setPumpCountLastMonth(pumpResponse.data.pumpCountLastMonth);

        setPumpGallons(pumpResponse.data.pumpGallons);
        setPumpGallonsMonth(pumpResponse.data.pumpGallonsMonth);
        setPumpGallonsLastMonth(pumpResponse.data.pumpGallonsLastMonth);

        setOverduePump(pumpResponse.data.overduePump);
        setOverduePumpMonth(pumpResponse.data.overduePumpMonth);
        setOverduePumpLastMonth(pumpResponse.data.overduePumpLastMonth);
      } else {
        presentToast('Could not load pump activity.', 'badToast');
        setLoading(false);
        return;
      }

      let yCurrentValues: number[] = [];
      let yLastValues: number[] = [];
      let yOtherValues: number[] = [];

      if (disposalResponse.success === true && disposalResponse.status === 200) {
        yCurrentValues = disposalResponse.data.currentOmusseeVolumes;
        yLastValues = disposalResponse.data.currentOtherVolumes;
        yOtherValues = disposalResponse.data.lastOmusseeVolumes;
      } else {
        presentToast('Could not load disposal activity.', 'badToast');
        setLoading(false);
        return;
      }

      let currentDate = new Date();
      setCurrentMonth(currentDate.toLocaleString('default', { month: 'long' }));

      let lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
      setLastMonth(lastMonth.toLocaleString('default', { month: 'long' }));

      let currentYear = currentDate.getFullYear();
      let lastYear = currentYear - 1;

      let max = Math.max(...yCurrentValues, ...yLastValues, ...yOtherValues);
      let min = Math.min(...yCurrentValues, ...yLastValues, ...yOtherValues);
      let extra = Math.round((max - min) / 8); //divide by 8 like the nether

      let optionsForChart = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: { caretPadding: 4 },
        },
        interaction: {
          axis: 'x',
          mode: 'nearest'
        },
        hitRadius: 512,
        hoverRadius: 6,
        spanGaps: true,
        scales: {
          y: {
            suggestedMax: max + extra,
            suggestedMin: ((min - extra) > 0 ? (min - extra) : 0),
            title: {
              display: true,
              text: 'Gallons',
              padding: { bottom: -4 },
            },
          }
        },
      }

      let dataForChart = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            type: 'line',
            label: `Omussee (${currentYear})`,
            data: yCurrentValues,
            borderColor: '#455465',
            backgroundColor: '#45546544',
            pointHoverBackgroundColor: '#455465',
            pointRadius: 2,
            borderWidth: 2,
            fill: true,
          },
          {
            type: 'line',
            label: `Omussee (${lastYear})`,
            data: yLastValues,
            borderColor: '#f58440',
            backgroundColor: '#f5844022',
            pointHoverBackgroundColor: '#f58440',
            pointRadius: 2,
            borderWidth: 2,
            fill: true,
          },
          {
            type: 'line',
            label: 'Other Locations',
            data: yOtherValues,
            borderColor: '#2da36f',
            backgroundColor: '#2da36f22',
            pointHoverBackgroundColor: '#2da36f',
            pointRadius: 2,
            borderWidth: 2,
            fill: true,
          },
        ],
      };

      setChartOptions(optionsForChart);
      setChartData(dataForChart);
    } catch (e) {
      console.log(e);
      presentToast('An error has occurred.', 'badToast');
    }

    setLoading(false);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              (!chartOptions || !chartData || (inspectionCount === undefined) || (inspectionCountMonth === undefined) || (inspectionCountLastMonth === undefined) || (compliantInspections === undefined) || (compliantInspectionsMonth === undefined) || (compliantInspectionsLastMonth === undefined) || (failedInspections === undefined) || (failedInspectionsMonth === undefined) || (failedInspectionsLastMonth === undefined) || (overdueInspections === undefined) || (overdueInspectionsMonth === undefined) || (overdueInspectionsLastMonth === undefined) || (pumpCount === undefined) || (pumpCountMonth === undefined) || (pumpCountLastMonth === undefined) || (pumpGallons === undefined) || (pumpGallonsMonth === undefined) || (pumpGallonsLastMonth === undefined) || (overduePump === undefined) || (overduePumpMonth === undefined) || (overduePumpLastMonth === undefined)) ? null :
                <>
                  <div className='defaultSubsection'>
                    <span className='defaultLargeText'>Inspection Data</span>
                    <div className='adminDashboardToggleButton' onClick={() => setInspectionViewMode(!inspectionViewMode)}>
                      <span className='defaultMediumText'>
                        <span className={inspectionViewMode ? 'nonselected' : 'selected'}>Monthly</span>
                        <span className='default'> | </span>
                        <span className={inspectionViewMode ? 'selected' : 'nonselected'}>All Time</span>
                      </span>
                    </div>
                  </div>

                  <div className='adminDashboardGrid defaultMarginTop'>
                    <div className='adminDashboardInfo'>
                      <span className='defaultMediumText boldText centerText'>{inspectionViewMode ? 'Total' : currentMonth} Inspection Count</span>
                      <span className='massiveText linkText' onClick={() => history.push('/report/inspections/all')}>{inspectionViewMode ? inspectionCount : inspectionCountMonth}</span>
                      {inspectionViewMode ? <span className='defaultMediumText'>Inspections</span> : <span className='defaultMediumText'>{inspectionCountLastMonth} in {lastMonth}</span>}
                    </div>

                    <div className='adminDashboardInfo'>
                      <span className='defaultMediumText boldText centerText'>{inspectionViewMode ? 'Total' : currentMonth} Compliant Inspections</span>
                      <span className='massiveText linkText' onClick={() => history.push('/report/inspections/compliant')}>{inspectionViewMode ? compliantInspections : compliantInspectionsMonth}</span>
                      {inspectionViewMode ? <span className='defaultMediumText'>Inspections</span> : <span className='defaultMediumText'>{compliantInspectionsLastMonth} in {lastMonth}</span>}
                    </div>

                    <div className='adminDashboardInfo'>
                      <span className='defaultMediumText boldText centerText'>{inspectionViewMode ? 'Total' : currentMonth} Failed Inspections</span>
                      <span className='massiveText linkText' onClick={() => history.push('/report/inspections/failed')}>{inspectionViewMode ? failedInspections : failedInspectionsMonth}</span>
                      {inspectionViewMode ? <span className='defaultMediumText'>Inspections</span> : <span className='defaultMediumText'>{failedInspectionsLastMonth} in {lastMonth}</span>}
                    </div>

                    <div className='adminDashboardInfo'>
                      <span className='defaultMediumText boldText centerText'>{inspectionViewMode ? 'Total' : currentMonth} Past Due Inspections</span>
                      <span className='massiveText linkText' onClick={() => history.push('/report/inspections')}>{inspectionViewMode ? overdueInspections : overdueInspectionsMonth}</span>
                      {inspectionViewMode ? <span className='defaultMediumText'>Inspections</span> : <span className='defaultMediumText'>{overdueInspectionsLastMonth} in {lastMonth}</span>}
                    </div>
                  </div>

                  <div className='defaultSubsection largeMarginTop'>
                    <span className='defaultLargeText'>Pump Data</span>
                    <div className='adminDashboardToggleButton' onClick={() => setPumpViewMode(!pumpViewMode)}>
                      <span className='defaultMediumText'>
                        <span className={pumpViewMode ? 'nonselected' : 'selected'}>Monthly</span>
                        <span className='default'> | </span>
                        <span className={pumpViewMode ? 'selected' : 'nonselected'}>All Time</span>
                      </span>
                    </div>
                  </div>

                  <div className='adminDashboardGrid defaultMarginTop'>
                    <div className='adminDashboardInfo'>
                      <span className='defaultMediumText boldText centerText'>{pumpViewMode ? 'Total' : currentMonth} Pump Count</span>
                      <span className='massiveText linkText' onClick={() => history.push('/report/pumps')}>{pumpViewMode ? pumpCount : pumpCountMonth}</span>
                      {pumpViewMode ? <span className='defaultMediumText'>Pump Activities</span> : <span className='defaultMediumText'>{pumpCountLastMonth} in {lastMonth}</span>}
                    </div>

                    <div className='adminDashboardInfo'>
                      <span className='defaultMediumText boldText centerText'>{pumpViewMode ? 'Total' : currentMonth} FOG Gallons Pumped</span>
                      <span className='massiveText linkText' onClick={() => history.push('/report/pumps')}>{Math.round((pumpViewMode ? pumpGallons : pumpGallonsMonth) * 100) / 100}</span>
                      {pumpViewMode ? <span className='defaultMediumText'>Gallons</span> : <span className='defaultMediumText'>{pumpGallonsLastMonth} in {lastMonth}</span>}
                    </div>

                    {/* <div className='adminDashboardInfo'>
                      <span className='defaultMediumText boldText centerText'>{pumpViewMode ? 'Total' : currentMonth} Past Due Pump Activitiy</span>
                      <span className='massiveText linkText' onClick={() => history.push('/report/pumps')}>{pumpViewMode ? overduePump : overduePumpMonth}</span>
                      {pumpViewMode ? <span className='defaultMediumText'>Pump Activities</span> : <span className='defaultMediumText'>{overduePumpLastMonth} in {lastMonth}</span>}
                    </div> */}
                  </div>

                  <span className='defaultLargeText largeMarginTop'>FOG Disposal Data</span>

                  <Line className='dashboardChart defaultMarginTop' options={chartOptions} data={chartData} />
                </>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
