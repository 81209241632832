import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import SelectInput from '../components/SelectInput';
import ToggleSwitch from '../components/ToggleSwitch';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './FatsInspect.css';

const FatsInspect: React.FC = () => {

  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [locationSearchTerm, setLocationSearchTerm] = useState<string>('');
  const [prevSearchTerm, setPrevSearchTerm] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const [potentialLocations, setPotentialLocations] = useState<any[]>([]);
  const [location, setLocation] = useState<any>();

  const [inspectionTypes, setInspectionTypes] = useState<{ [key: string]: any }>();
  const [inspectionTypeId, setInspectionTypeId] = useState<number>(1); // 1=GGI,2=HGI

  const [ggiPercTotalSolids, setGgiPercTotalSolids] = useState<string>(''); //GGI
  const [ggiClean, setGgiClean] = useState<boolean>(false);
  const [ggiAccess, setGgiAccess] = useState<boolean>(false);
  const [ggiInletTeeAttached, setGgiInletTeeAttached] = useState<boolean>(false);
  const [ggiOutletTeeAttached, setGgiOutletTeeAttached] = useState<boolean>(false);
  const [ggiTeesMaterial, setGgiTeesMaterial] = useState<boolean>(false);
  const [ggiCondition, setGgiCondition] = useState<boolean>(false);
  const [ggiBaffle, setGgiBaffle] = useState<boolean>(false);
  const [ggiBafflePlumbing, setGgiBafflePlumbing] = useState<boolean>(false);
  const [ggiCoCoverIssue, setGgiCoCoverIssue] = useState<string>('N/A'); //nullable
  const [ggiCorrectiveActions, setGgiCorrectiveActions] = useState<string>(''); //required if !inspectionPassed()
  const [ggiCorrectionsExpectedDate, setGgiCorrectionsExpectedDate] = useState<Date | null>(null); //required if !inspectionPassed()
  const [ggiCapacity, setGgiCapacity] = useState<string>('');

  const [hgiPercTotalSolids, setHgiPercTotalSolids] = useState<string>(''); //HGI
  const [hgiClean, setHgiClean] = useState<boolean>(false);
  const [hgiAccess, setHgiAccess] = useState<boolean>(false);
  const [hgiFlowControlInletSide, setHgiFlowControlInletSide] = useState<boolean>(false);
  const [hgiControlOriented, setHgiControlOriented] = useState<boolean | null>(null); //required if hgiFlowControlInletSide
  const [hgiControlVented, setHgiControlVented] = useState<boolean | null>(null); //required if hgiFlowControlInletSide
  const [hgiCondition, setHgiCondition] = useState<boolean>(false);
  const [hgiBaffle, setHgiBaffle] = useState<boolean>(false);
  const [hgiSewerConnected, setHgiSewerConnected] = useState<boolean>(false);
  const [hgiCoCoverIssue, setHgiCoCoverIssue] = useState<string>('N/A'); //nullable
  const [hgiCorrectiveActions, setHgiCorrectiveActions] = useState<string>(''); //required if !inspectionPassed()
  const [hgiCorrectionsExpectedDate, setHgiCorrectionsExpectedDate] = useState<Date | null>(null); //required if !inspectionPassed()
  const [hgiFlowRate, setHgiFlowRate] = useState<string>('');

  const [date, setDate] = useState<any>(new Date());

  const [submitInspectionLoading, setSubmitInspectionLoading] = useState<boolean>(false);

  const nullableValueMap: { [key: string]: any } = {
    'Yes': true,
    'No': false,
    'N/A': null,
  }


  useEffect(() => {
    if (result !== null) initFatsInspect();
  }, [result]);


  const initFatsInspect = async () => {
    try {
      let response = await httpCall('GET', 'InspectionType');
      if (response.success === true && response.status === 200) {
        setInspectionTypes(response.data);
      } else {
        presentToast('Could not load inspection types.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Error loading inspection types.', 'badToast');
    }

    setLoading(false);
  }


  const handleFseSearch = async () => {
    if (searchLoading) return;
    setSearchLoading(true);

    try {
      let response = await httpCall('GET', `ArcGIS/searchfse?query=${locationSearchTerm}`);
      if (response.success === true && response.status === 200) {
        setPrevSearchTerm(locationSearchTerm);
        setPotentialLocations(response.data);
      } else {
        presentToast('Failed to search FSEs.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Failed to search FSEs.', 'badToast');
    }

    setSearchLoading(false);
    setHasSearched(true);
  }


  const handleSetHgiFlowControlInletSide = (valueIn: boolean) => {
    if (valueIn) {
      setHgiControlOriented(false);
      setHgiControlVented(false);
    }

    setHgiFlowControlInletSide(valueIn);

    if (!valueIn) {
      setHgiControlOriented(null);
      setHgiControlVented(null);
    }
  }


  const inspectionPassed = () => {
    if ((inspectionTypeId === 1 && ggiAccess && ggiInletTeeAttached && ggiOutletTeeAttached && ggiTeesMaterial && ggiCondition && ggiBaffle && ggiBafflePlumbing && (ggiCoCoverIssue === 'Yes' || ggiCoCoverIssue === 'N/A'))
      || (inspectionTypeId === 2 && hgiClean && hgiAccess && (!hgiFlowControlInletSide || (hgiControlOriented && hgiControlVented)) && hgiCondition && hgiBaffle && hgiSewerConnected && (hgiCoCoverIssue === 'Yes' || hgiCoCoverIssue === 'N/A'))) { //very readable
      return true;
    }
    return false;
  }


  const handleSubmitInspection = async () => {
    if (submitInspectionLoading) return;

    if (!location?.assetId) {
      presentToast('An error occurred.', 'badToast');
      return;
    }

    if ((inspectionTypeId === 1 && (!ggiPercTotalSolids || !ggiCapacity)) || (inspectionTypeId === 2 && (!hgiPercTotalSolids || !hgiFlowRate))) {
      presentToast('Please fill out required fields.', 'badToast');
      return;
    };

    setSubmitInspectionLoading(true);

    try {
      let passed = inspectionPassed();

      let postData: { [key: string]: any } = {
        annualCertificationGGI: null,
        annualCertificationHGI: null,
      };

      if (inspectionTypeId === 1) {
        postData.annualCertificationGGI = {
          fseId: location.assetId,
          gceId: location.gcEs[0]?.assetId,
          inspectionTypeId: inspectionTypeId,
          date: date.toISOString(),
          inspectionPassed: passed,
          percTotalSolids: parseFloat(ggiPercTotalSolids),
          clean: ggiClean,
          access: ggiAccess,
          inletTeeAttached: ggiInletTeeAttached,
          outletTeeAttached: ggiOutletTeeAttached,
          teesMaterial: ggiTeesMaterial,
          condition: ggiCondition,
          baffle: ggiBaffle,
          bafflePlumbing: ggiBafflePlumbing,
          coCoverIssue: nullableValueMap[ggiCoCoverIssue],
          correctiveActions: passed ? null : ggiCorrectiveActions,
          correctionsExpectedDate: passed ? null : ggiCorrectionsExpectedDate,
          capacity: parseInt(ggiCapacity),
        };
      } else if (inspectionTypeId === 2) {
        postData.annualCertificationHGI = {
          fseId: location.assetId,
          gceId: location.gcEs[0]?.assetId,
          inspectionTypeId: inspectionTypeId,
          date: date.toISOString(),
          inspectionPassed: passed,
          percTotalSolids: parseFloat(hgiPercTotalSolids),
          clean: hgiClean,
          access: hgiAccess,
          flowControlInletSide: hgiFlowControlInletSide,
          controlOriented: hgiControlOriented,
          controlVented: hgiControlVented,
          condition: hgiCondition,
          baffle: hgiBaffle,
          sewerConnected: hgiSewerConnected,
          coCoverIssue: nullableValueMap[hgiCoCoverIssue],
          correctiveActions: passed ? null : hgiCorrectiveActions,
          correctionsExpectedDate: passed ? null : hgiCorrectionsExpectedDate,
          flowRate: parseInt(hgiFlowRate),
        };
      }

      let response = await httpCall('POST', 'Inspection', undefined, undefined, postData);
      if (response.success === true && response.status === 201) {
        presentToast('Inspection saved.', 'goodToast');
        history.push('/fats');
      } else {
        presentToast('Failed to save inspection.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Failed to save inspection.', 'badToast');
    }

    setSubmitInspectionLoading(false);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>Inspection Report</span>

                {
                  location ? <>
                    <div className='defaultSection defaultMarginTop'>
                      <span className='defaultMediumText boldText'>Establishment:</span>

                      <span className='defaultMediumText'>Name: {location.name}</span>

                      <span className='defaultMediumText'>Address: {location.address}</span>

                      <span className='defaultMediumText'>Phone: {location.phoneNumber}</span>

                      <div className='defaultEditGrid'>
                        <span className='defaultMediumText boldText'>Inspection Type:</span>
                        <SelectInput
                          sections={[{
                            options: inspectionTypes ? Object.fromEntries(
                              inspectionTypes.map((type: any) => [type.type, type.inspectionTypeId])
                            ) : {}
                          }]}
                          value={inspectionTypeId}
                          setValue={(value: string) => setInspectionTypeId(Number(value))}
                        />
                      </div>
                    </div>

                    <div className='defaultEditGrid defaultSectionMid' style={{ gap: '1.5rem' }}>
                      {
                        inspectionTypeId !== 1 ? null : <>
                          <span className='defaultMediumText boldText'>% FOG and Solids:</span>
                          <input className='textInput inputShort' type='number' value={ggiPercTotalSolids} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGgiPercTotalSolids(e.target.value)} />

                          <span className='defaultMediumText boldText'>Interceptor completely emptied and cleaned before inspection?</span>
                          <ToggleSwitch toggleId='ggiClean' isOn={ggiClean} handleToggle={setGgiClean} />

                          <span className='defaultMediumText boldText'>Access is available to all interceptor chambers for cleaning and maintenance?</span>
                          <ToggleSwitch toggleId='ggiAccess' isOn={ggiAccess} handleToggle={setGgiAccess} />

                          <span className='defaultMediumText boldText'>Inlet tee is firmly attached to prevent the escape of FOG and extends downward at least 2/3 depth of water?</span>
                          <ToggleSwitch toggleId='ggiInletTeeAttached' isOn={ggiInletTeeAttached} handleToggle={setGgiInletTeeAttached} />

                          <span className='defaultMediumText boldText'>Outlet tee is firmly attached to prevent the escape of FOG and extends downward to within 12" of tank bottom?</span>
                          <ToggleSwitch toggleId='ggiOutletTeeAttached' isOn={ggiOutletTeeAttached} handleToggle={setGgiOutletTeeAttached} />

                          <span className='defaultMediumText boldText'>Inlet and outlet tees are made of a non-collapsible material that does not easily flex or bend (minimum Schedule 40 PVC)?</span>
                          <ToggleSwitch toggleId='ggiTeesMaterial' isOn={ggiTeesMaterial} handleToggle={setGgiTeesMaterial} />

                          <span className='defaultMediumText boldText'>Interceptor is free of visible holes, leaks, or severe deterioration?</span>
                          <ToggleSwitch toggleId='ggiCondition' isOn={ggiCondition} handleToggle={setGgiCondition} />

                          <span className='defaultMediumText boldText'>Baffle wall is secure and operational.</span>
                          <ToggleSwitch toggleId='ggiBaffle' isOn={ggiBaffle} handleToggle={setGgiBaffle} />

                          <span className='defaultMediumText boldText'>Plumbing through the baffle consists of a tee or sweep placed in the vertical position on the inlet compartment and extends 12" above the floor, or a slotted baffle is visible and in good condition.</span>
                          <ToggleSwitch toggleId='ggiBafflePlumbing' isOn={ggiBafflePlumbing} handleToggle={setGgiBafflePlumbing} />

                          <span className='defaultMediumText boldText'>No sewer clean-out covers are missing, damaged, or grated? If not present, check N/A.</span>
                          <SelectInput
                            sections={[{ options: Object.fromEntries(Object.keys(nullableValueMap).map((value: string) => [value, value])) }]}
                            value={ggiCoCoverIssue}
                            setValue={setGgiCoCoverIssue}
                          />
                        </>
                      }

                      {
                        inspectionTypeId !== 2 ? null : <>
                          <span className='defaultMediumText boldText'>% FOG and Solids:</span>
                          <input className='textInput inputShort' type='number' value={hgiPercTotalSolids} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHgiPercTotalSolids(e.target.value)} />

                          <span className='defaultMediumText boldText'>HGI completely emptied and cleaned before inspection?</span>
                          <ToggleSwitch toggleId='hgiClean' isOn={hgiClean} handleToggle={setHgiClean} />

                          <span className='defaultMediumText boldText'>Access to all chambers is available for monthly cleanings?</span>
                          <ToggleSwitch toggleId='hgiAccess' isOn={hgiAccess} handleToggle={setHgiAccess} />

                          <span className='defaultMediumText boldText'>Flow control device is installed and visible on the inlet side of the trap?</span>
                          <ToggleSwitch toggleId='hgiFlowControlInletSide' isOn={hgiFlowControlInletSide} handleToggle={handleSetHgiFlowControlInletSide} />

                          {
                            !hgiFlowControlInletSide ? null : <>
                              <span className='defaultMediumText boldText'>Flow control device orientation is correct?</span>
                              <ToggleSwitch toggleId='hgiControlOriented' isOn={hgiControlOriented === null ? false : hgiControlOriented} handleToggle={setHgiControlOriented} />

                              <span className='defaultMediumText boldText'>HGIs flow control device is sufficiently vented?</span>
                              <ToggleSwitch toggleId='hgiControlVented' isOn={hgiControlVented === null ? false : hgiControlVented} handleToggle={setHgiControlVented} />
                            </>
                          }

                          <span className='defaultMediumText boldText'>HGI has no visible holes or leaks and shows no significant signs of deterioration?</span>
                          <ToggleSwitch toggleId='hgiCondition' isOn={hgiCondition} handleToggle={setHgiCondition} />

                          <span className='defaultMediumText boldText'>The baffle is secure and operational?</span>
                          <ToggleSwitch toggleId='hgiBaffle' isOn={hgiBaffle} handleToggle={setHgiBaffle} />

                          <span className='defaultMediumText boldText'>HGI is directly connected to city sewer (no air gaps)?</span>
                          <ToggleSwitch toggleId='hgiSewerConnected' isOn={hgiSewerConnected} handleToggle={setHgiSewerConnected} />

                          <span className='defaultMediumText boldText'>Clean out covers are not missing, damaged, or grated? If not present, check N/A.</span>
                          <SelectInput
                            sections={[{ options: Object.fromEntries(Object.keys(nullableValueMap).map((value: string) => [value, value])) }]}
                            value={hgiCoCoverIssue}
                            setValue={setHgiCoCoverIssue}
                          />
                        </>
                      }
                    </div>

                    <div className='defaultSection'>
                      <span className='defaultMediumText'>Inspection Result: {inspectionPassed() ? <span className='successText boldText'>PASSED</span> : <span className='dangerText boldText'>FAILED</span>}</span>

                      {
                        inspectionTypeId !== 1 ? null : <>
                          {
                            inspectionPassed() ? null : <>
                              <span className='defaultMediumText boldText'>Corrective Action Response:</span>
                              <textarea className='textArea' value={ggiCorrectiveActions} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setGgiCorrectiveActions(e.target.value)} />

                              <div className='defaultEditGrid'>
                                <span className='defaultMediumText boldText'>Expected Completion Date:</span>
                                <div>
                                  <DatePicker className='textInput' selected={ggiCorrectionsExpectedDate} onChange={(d: any) => setGgiCorrectionsExpectedDate(d)} />
                                </div>
                              </div>
                            </>
                          }

                          <span className='defaultMediumText'>
                            I have made a visual inspection of the GGI at the above establishment and determined it to have a
                            <input className='textInput textInputInSpan inputShort' type='number' value={ggiCapacity} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGgiCapacity(e.target.value)} />
                            <span className='boldtext'>gallon capacity</span>
                            . I attest that the information provided is true and accurate and understand that falsification of this record can result in removal from the City of Dothan's certified list of contractors.
                          </span>
                        </>
                      }

                      {
                        inspectionTypeId !== 2 ? null : <>
                          {
                            inspectionPassed() ? null : <>
                              <span className='defaultMediumText'>Corrective Action Response:</span>
                              <textarea className='textArea' value={hgiCorrectiveActions} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setHgiCorrectiveActions(e.target.value)} />

                              <div className='defaultEditGrid'>
                                <span className='defaultMediumText boldText'>Expected Completion Date:</span>
                                <div>
                                  <DatePicker className='textInput' selected={hgiCorrectionsExpectedDate} onChange={(d: any) => setHgiCorrectionsExpectedDate(d)} />
                                </div>
                              </div>
                            </>
                          }

                          <span className='defaultMediumText'>
                            I have made a visual inspection of the HGI at the above establishment and determined it to have a flow rate of
                            <input className='textInput textInputInSpan inputShort' type='number' value={hgiFlowRate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHgiFlowRate(e.target.value)} />
                            <span className='boldtext'>gallons per minute</span>
                            . I attest that the information provided is true and accurate and understand that falsification of this record can result in removal from the City of Dothan's certified list of contractors.
                          </span>
                        </>
                      }

                      <span className='defaultSmallText'>User identity will automatically be recorded.</span>

                      <div className='defaultEditGrid'>
                        <span className='defaultMediumText boldText'>Date and Time:</span>
                        <div>
                          <DatePicker className='textInput' selected={date} onChange={(d: any) => setDate(d)} showTimeSelect />
                        </div>
                      </div>
                    </div>
                  </> : (
                    <div className='defaultSection defaultMarginTop'>
                      <div className='defaultSubsection'>
                        <span className='defaultMediumText boldText'>Search for Location:</span>
                        <input className='textInput' type='text' value={locationSearchTerm} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocationSearchTerm(e.target.value)} />
                        {
                          (locationSearchTerm.length > 0 && locationSearchTerm !== prevSearchTerm) ?
                            <div className='smallButton' onClick={handleFseSearch}>
                              {searchLoading ? <IonSpinner name='crescent' /> : <span>Search</span>}
                            </div> : null
                        }
                      </div>

                      {
                        potentialLocations.length > 0 ?
                          <div className='defaultEditGrid'>
                            {
                              potentialLocations.map((l: any) => <Fragment key={l.assetId}>
                                <div className='defaultColumnWrapper'>
                                  <span className='defaultMediumText'>{l.name}</span>
                                  <span className='defaultSmallText'>{l.location}</span>
                                </div>
                                <div className='smallButton' onClick={() => setLocation(l)}>Select</div>
                              </Fragment>)
                            }
                          </div>
                          :
                          hasSearched ? <span className='defaultMediumText dangerText'>No results found.</span> : null
                      }
                    </div>
                  )
                }

                <div className='smallButtonWrapper mediumMarginTop'>
                  <div className='smallButton' onClick={() => location ? setLocation(undefined) : history.goBack()}>
                    <span>Cancel</span>
                  </div>
                  {
                    location ?
                      <div className='smallButton' onClick={handleSubmitInspection}>
                        {submitInspectionLoading ? <IonSpinner name='crescent' /> : <span>Submit</span>}
                      </div> : null
                  }
                </div>
              </>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FatsInspect;
