import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './Fats.css';

const Fats: React.FC = () => {

  const { instance } = useMsal();
  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const inspectionUserRoleIds = [1, 3, 4, 8];
  const pumpUserRoleIds = [1, 4, 5];
  const disposalUserRoleIds = [1, 4, 5];

  const [loading, setLoading] = useState<boolean>(true);

  const [userRole, setUserRole] = useState<number>(0);

  const [inspectionCount, setInspectionCount] = useState<number>(-1);
  const [pumpCount, setPumpCount] = useState<number>(-1);
  const [disposalCount, setDisposalCount] = useState<number>(-1);


  useEffect(() => {
    if (result !== null) initFATS();
  }, [result]);


  const initFATS = async () => {
    try {
      let accountClaims = instance.getActiveAccount()?.idTokenClaims;
      let role = accountClaims?.extension_APPRole;
      if (typeof role === 'number') { //this is so dumb, extension_APPRole is always a number and never a {}, but it says its {}....
        setUserRole(role);

        let [inspectionResponse, pumpResponse, disposalResponse] = await Promise.all([
          (inspectionUserRoleIds.includes(role) ? httpCall('GET', 'Inspection?count=true&user=true') : Promise.resolve(null)),
          (pumpUserRoleIds.includes(role) ? httpCall('GET', 'Pumpactivity?count=true&user=true') : Promise.resolve(null)),
          (disposalUserRoleIds.includes(role) ? httpCall('GET', 'Disposal?count=true&user=true') : Promise.resolve(null)),
        ]);

        if (inspectionResponse) {
          if (inspectionResponse.success === true && inspectionResponse.status === 200) {
            setInspectionCount(inspectionResponse.data);
          } else if (inspectionResponse) {
            presentToast('Could not load inspection activity.', 'badToast');
          }
        }

        if (pumpResponse) {
          if (pumpResponse.success === true && pumpResponse.status === 200) {
            setPumpCount(pumpResponse.data);
          } else if (pumpResponse) {
            presentToast('Could not load pump activity.', 'badToast');
          }
        }

        if (disposalResponse) {
          if (disposalResponse.success === true && disposalResponse.status === 200) {
            setDisposalCount(disposalResponse.data);
          } else if (disposalResponse) {
            presentToast('Could not load disposal activity.', 'badToast');
          }
        }
      } else {
        presentToast('A token error occurred. Please log out and try again.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An api error occurred.', 'badToast');
    }

    setLoading(false);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              <>
                <span className='defaultLargeText'>FATS</span>

                <div className='fatsInfoGrid defaultSection defaultMarginTop'>
                  {
                    inspectionUserRoleIds.includes(userRole) ?
                      <div className='fatsInfoWrapper'>
                        <span className='defaultMediumText'>Your Inspections:</span>
                        <span className='massiveText linkText' onClick={() => history.push('/activity/inspections')}>{inspectionCount === -1 ? '-' : inspectionCount}</span>
                        <div className='largeButton defaultMarginTop' onClick={() => history.push('/fatsinspect')}>
                          <span>Inspect</span>
                        </div>
                      </div> : null
                  }

                  {
                    pumpUserRoleIds.includes(userRole) ?
                      <div className='fatsInfoWrapper'>
                        <span className='defaultMediumText'>Your Pump Activity:</span>
                        <span className='massiveText linkText' onClick={() => history.push('/activity/pumps')}>{pumpCount === -1 ? '-' : pumpCount}</span>
                        <div className='largeButton defaultMarginTop' onClick={() => history.push('/fatspump')}>
                          <span>Pump</span>
                        </div>
                      </div> : null
                  }

                  {
                    disposalUserRoleIds.includes(userRole) ?
                      <div className='fatsInfoWrapper'>
                        <span className='defaultMediumText'>Your Disposal Gallons:</span>
                        <span className='massiveText linkText' onClick={() => history.push('/activity/disposals')}>{disposalCount === -1 ? '-' : disposalCount}</span>
                        <div className='largeButton defaultMarginTop' onClick={() => history.push('/fatsdispose')}>
                          <span>Dispose</span>
                        </div>
                      </div> : null
                  }
                </div>
              </>
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Fats;
