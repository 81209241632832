import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useIonAlert } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { FaCaretDown } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';
import { b2cPolicies } from '../utils/authConfig';
import { useCustomToast } from '../utils/toast';
import './Header.css';

const Header: React.FC = () => {

  const { instance, inProgress } = useMsal();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const presentToast = useCustomToast();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const manageOptionsWrapper = useRef<HTMLDivElement>(null);
  const [manageOptionsOpen, setManageOptionsOpen] = useState<boolean>(false);
  const [manageOptionOne, setManageOptionOne] = useState<boolean>(false);
  const [manageOptionTwo, setManageOptionTwo] = useState<boolean>(false);
  const [manageOptionThree, setManageOptionThree] = useState<boolean>(false);
  const [manageOptionFour, setManageOptionFour] = useState<boolean>(false);


  useEffect(() => {
    let activeAccount = instance.getActiveAccount();
    if (activeAccount?.idTokenClaims && (activeAccount.idTokenClaims.extension_APPRole === 1 || activeAccount.idTokenClaims.extension_APPRole === 4)) {
      setIsAdmin(true);
    }
  });


  const handleDownloadDocs = () => {
    let pdfPath = 'FATS_Documentation.pdf';
    let anchor = document.createElement('a');
    anchor.href = pdfPath;
    anchor.download = 'FATS_Documentation.pdf';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }


  const handleProfileEdit = () => {
    try {
      if (inProgress === InteractionStatus.None) {
        let editProfileRequest = {
          authority: b2cPolicies.authorities.editProfile.authority,
          scopes: ['openid', 'profile']
        };
        instance.loginRedirect(editProfileRequest);
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }
  }


  const handleForgotPassword = () => {
    try {
      if (inProgress === InteractionStatus.None) {
        const editProfileRequest = {
          authority: b2cPolicies.authorities.forgotPassword.authority,
          scopes: []
        };
        instance.acquireTokenRedirect(editProfileRequest);
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }
  };


  const handleLogout = async () => {
    presentAlert({
      header: 'Are you sure you want to log out?',
      buttons: [
        { text: 'Cancel', role: 'cancel', },
        {
          text: 'Log Out', role: 'confirm', handler: async () => {
            try {
              await instance.logoutRedirect();
            } catch (e) {
              console.log(e);
              presentToast('Error logging out.', 'badToast');
            }
          },
        },
      ],
    });
  }


  return (
    <div className='headerContentWrapper'>
      <div className='headerContent'>
        <div className='headerLogoWrapper'>
          <img className='headerLogo' src='/logo192.png' alt='B' />
          <span className='headerLogoSpan'>FATS</span>
        </div>

        <span className='headerNavButton defaultMediumText' onClick={handleProfileEdit}>Account</span>

        {
          isAdmin ? <>
            <span className='headerNavButton defaultMediumText' onClick={() => history.push('/dashboard')}>Dashboard</span>

            <span className={`headerNavButton headerNavButtonWithCaret defaultMediumText${manageOptionsOpen ? ' headerNavButtonOpen' : ''}`} onMouseEnter={() => setManageOptionsOpen(true)} onMouseLeave={() => setManageOptionsOpen(false)}>
              Manage<FaCaretDown className={`defaultMediumText headerNavButtonCaretDown${manageOptionsOpen ? ' headerNavButtonCaretUp' : ''}`} />
              <div ref={manageOptionsWrapper} className={`headerNavMenu${manageOptionsOpen ? ' headerNavMenuOpen' : ''}`} style={{ maxHeight: manageOptionsOpen ? (manageOptionsWrapper.current ? `calc(${manageOptionsWrapper.current.scrollHeight}px + 1rem)` : 'unset') : '0' }}>
                <span className={`defaultMediumText${manageOptionOne ? ' activeSpan' : ''}`} onClick={() => history.push('/manageusers')} onMouseEnter={() => setManageOptionOne(true)} onMouseLeave={() => setManageOptionOne(false)}>Users</span>
                <span className={`defaultMediumText${manageOptionTwo ? ' activeSpan' : ''}`} onClick={() => history.push('/manageinvites')} onMouseEnter={() => setManageOptionTwo(true)} onMouseLeave={() => setManageOptionTwo(false)}>Invites</span>
                <span className={`defaultMediumText${manageOptionThree ? ' activeSpan' : ''}`} onClick={() => history.push('/managevendors')} onMouseEnter={() => setManageOptionThree(true)} onMouseLeave={() => setManageOptionThree(false)}>Vendors</span>
                <span className={`defaultMediumText${manageOptionFour ? ' activeSpan' : ''}`} onClick={() => history.push('/managetrucks')} onMouseEnter={() => setManageOptionFour(true)} onMouseLeave={() => setManageOptionFour(false)}>Trucks</span>
              </div>
            </span>

            <span className='headerNavButton defaultMediumText' onClick={() => history.push('/report')}>Reports</span>

            <span className='headerNavButton defaultMediumText' onClick={() => history.push('/fats')}>FATS</span>

            <span className='headerNavButton defaultMediumText' onClick={handleDownloadDocs}>Help</span>
          </> : null
        }

        <span className='headerNavButton headerLogoutButton defaultMediumText' onClick={handleLogout}>Log Out</span>
      </div>
    </div>
  );
};

export default Header;