import { useMsal } from '@azure/msal-react';
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Landing.css';

const Landing: React.FC = () => {

  const { instance, accounts } = useMsal();
  const history = useHistory();


  useEffect(() => {
    const activeAccount = instance.getActiveAccount();

    if (activeAccount && !activeAccount?.idTokenClaims?.extension_APPRole) {
      history.push('/login');
    }
  }, [accounts]);


  return (
    <IonPage>
      <IonContent>
        <div className='pageContent'>
          <IonSpinner name='crescent' className='pageSpinner' />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Landing;
