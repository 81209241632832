import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import './SelectInput.css';

type OptionTypes = string | number | boolean | undefined;

interface SelectInputSection {
  title?: string;
  options: { [key: string]: OptionTypes };
}

interface SelectInputProps {
  sections: SelectInputSection[];
  value: OptionTypes;
  setValue: any;
  placeholder?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({ sections, value, setValue, placeholder }) => {

  const selectInputRef = useRef<HTMLDivElement>(null);
  const [displayValue, setDisplayValue] = useState<string>();
  const [inputOpen, setInputOpen] = useState<boolean>(false);
  const [hoverValue, setHoverValue] = useState<OptionTypes>();


  const findKeyByValue = useCallback(() => {
    for (let section of sections) {
      for (const [key, val] of Object.entries(section.options)) {
        if (val === value) return key;
      }
    }
  }, [sections, value]);


  useEffect(() => {
    setDisplayValue(findKeyByValue() ?? placeholder);
    // eslint-disable-next-line
  }, [placeholder, findKeyByValue]);


  useEffect(() => {
    if (!inputOpen) return;
    setHoverValue(findKeyByValue());
    // eslint-disable-next-line
  }, [inputOpen, findKeyByValue]);


  const handleSetValue = (valueIn: OptionTypes) => {
    setValue(valueIn)
    setInputOpen(false)
  }


  return (
    <div className={`selectInputWrapper${inputOpen ? ' open' : ''}`} onMouseEnter={() => setInputOpen(true)} onMouseLeave={() => setInputOpen(false)}>
      <span className={`defaultMediumText overflow${displayValue === placeholder ? ' placeholder' : ''}`}>{displayValue}</span>
      <FaChevronDown className={`defaultSmallText selectInputCaret${inputOpen ? ' rotated' : ''}`} />

      <div ref={selectInputRef} className={`selectInputMenu${inputOpen ? ' open' : ''}`} style={{ maxHeight: inputOpen ? (selectInputRef.current ? `calc(${selectInputRef.current.scrollHeight}px + 1rem)` : 'unset') : '0' }}>
        {
          sections.map((section: SelectInputSection, index: number) =>
            <div key={index} className={`selectInputSection${index === 0 ? '' : ' divider'}`}>
              {
                !section.title ? null :
                  <span className='defaultMediumText title'>
                    <div className='titleLine' />
                    {section.title}
                  </span>
              }
              {
                Object.entries(section.options).map(([key, val]: [string, OptionTypes]) =>
                  <span key={key} className={`defaultMediumText${hoverValue === key ? ' active' : ''}`} onMouseEnter={() => setHoverValue(key)} onMouseLeave={() => setHoverValue(undefined)} onClick={() => handleSetValue(val)}>
                    {key}
                  </span>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default memo(SelectInput);
