import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../components/Header';
import PaginationTable from '../components/PaginationTable';
import useHttpCall from '../utils/http';
import { useCustomToast } from '../utils/toast';
import './ViewInspection.css';

interface ViewInspectionParams {
  inspectionTypeId: string;
  inspectionId: string;
}

const ViewInspection: React.FC = () => {

  const { inspectionTypeId, inspectionId } = useParams<ViewInspectionParams>();
  const history = useHistory();
  const { result, httpCall } = useHttpCall();
  const presentToast = useCustomToast();

  const inspectionTypeMap: { [key: number]: string } = {
    1: 'GGICert',
    2: 'HGICert',
  }

  const [loading, setLoading] = useState<boolean>(true);

  const [inspectionData, setInspectionData] = useState<any>();

  const [downloading, setDownloading] = useState<boolean>(false);


  useEffect(() => {
    if (result !== null) initViewInspection();
  }, [result]);


  const initViewInspection = async () => {
    try {
      let response = await httpCall('GET', 'Inspection', undefined, `${inspectionId}?inspectionType=${inspectionTypeId}`);
      if (response.success === true && response.status === 200) {
        if (response.data.user?.attributes) {
          if (response.data.user.attributes.firstName === 'Detached user') {
            response.data.user.attributes.fullName = response.data.user.attributes.firstName;
          } else if (response.data.user.attributes.firstName && response.data.user.attributes.lastName) {
            response.data.user.attributes.fullName = `${response.data.user.attributes.firstName} ${response.data.user.attributes.lastName}`;
          } else {
            response.data.user.attributes.fullName = response.data.user.attributes.firstName ?? response.data.user.attributes.lastName ?? '-';
          }
        }

        setInspectionData(response.data);
      } else {
        presentToast('Error getting inspection data.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('An error occurred.', 'badToast');
    }

    setLoading(false);
  }


  const valueToString = (valueIn: boolean | null) => {
    if (valueIn) {
      return 'Passed';
    } else if (valueIn === false) {
      return 'Failed';
    } else {
      return 'N/A';
    }
  }


  const handleDownloadReport = async () => {
    if (downloading) return;

    setDownloading(true);

    try {
      let reqAction = inspectionTypeMap[inspectionData.inspectionTypeId] || ''

      if (reqAction === '') {
        presentToast('An inspection report type error occurred.', 'badToast');
        return;
      }

      let response = await httpCall('GET', 'Report', `${reqAction}?id=${inspectionId}`, undefined, undefined, true);
      if (response.success === true && response.status === 200) {
        let url = URL.createObjectURL(response.data);
        let anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Inspection_Report.pdf'; //TODO: couldn't extract name from content-disposition header...
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      } else {
        presentToast('Failed to generate report.', 'badToast');
      }
    } catch (e) {
      console.log(e);
      presentToast('Error generating report.', 'badToast');
    }

    setDownloading(false);
  }


  return (
    <IonPage>
      <IonContent>
        <Header />

        <div className='pageContent'>
          {
            loading ? <IonSpinner name='crescent' className='pageSpinner' /> :
              inspectionData ?
                <>
                  <span className='defaultLargeText'>Inspection Details</span>

                  <div className='defaultSection defaultMarginTop'>
                    <span className='defaultMediumText'><span className='boldText'>Establishment Name:</span> {inspectionData.fse?.name}</span>

                    <span className='defaultMediumText'><span className='boldText'>Establishment Address:</span> {inspectionData.fse?.address}</span>

                    <span className='defaultMediumText'><span className='boldText'>Establishment Phone:</span> {inspectionData.fse?.phoneNumber}</span>

                    <span className='defaultMediumText'><span className='boldText'>Inspection Type:</span> {inspectionData.inspectionType?.type}</span>

                    <span className='defaultMediumText'><span className='boldText'>Inspector Name:</span> {inspectionData.user?.attributes?.fullName}</span>

                    <span className='defaultMediumText'><span className='boldText'>Inspector Vendor:</span> {inspectionData.user?.vendor?.name ?? 'N/A'}</span>

                    <span className='defaultMediumText'><span className='boldText'>Date and Time:</span> {new Date(inspectionData.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                  </div>

                  <div className='defaultSectionBot'>
                    {
                      inspectionData.inspectionTypeId !== 1 ? null : <>
                        <PaginationTable
                          noPagination={true}
                          labels={['Criteria', 'Result']}
                          elements={[
                            [<span className='defaultMediumText boldText defaultTableElement'>GGI Capacity:</span>,
                            <span className='defaultMediumText defaultTableElement'>{inspectionData.capacity} gallons</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>% FOG and Solids:</span>,
                            <span className='defaultMediumText defaultTableElement'>{inspectionData.percTotalSolids}%</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Interceptor completely emptied and cleaned before inspection?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.clean)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Access is available to all interceptor chambers for cleaning and maintenance?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.access)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Inlet tee is firmly attached to prevent the escape of FOG and extends downward at least 2/3 depth of water?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.inletTeeAttached)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Outlet tee is firmly attached to prevent the escape of FOG and extends downward to within 12" of tank bottom?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.outletTeeAttached)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Inlet and outlet tees are made of a non-collapsible material that does not easily flex or bend (minimum Schedule 40 PVC)?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.teesMaterial)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Interceptor is free of visible holes, leaks, or severe deterioration?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.condition)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Baffle wall is secure and operational.</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.baffle)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Plumbing through the baffle consists of a tee or sweep placed in the vertical position on the inlet compartment and extends 12" above the floor, or a slotted baffle is visible and in good condition.</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.bafflePlumbing)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>No sewer clean-out covers are missing, damaged, or grated? If not present, N/A.</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.coCoverIssue)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Inspection Result:</span>,
                            (inspectionData.inspectionPassed ? <span className='defaultMediumText successText boldText defaultTableElement'>PASSED</span> : <span className='defaultMediumText dangerText boldText defaultTableElement'>FAILED</span>),],
                          ]}
                        />

                        {
                          inspectionData.inspectionPassed ? null : <>
                            <span className='defaultMediumText boldText viewInspectionMaxWidth'>Corrective Action Response:</span>
                            <span className='defaultMediumText'>{inspectionData.correctiveActions}</span>

                            <span className='defaultMediumText boldText viewInspectionMaxWidth'>Expected Completion Date:</span>
                            <span className='defaultMediumText'>{new Date(inspectionData.correctionsExpectedDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</span>
                          </>
                        }
                      </>
                    }

                    {
                      inspectionData.inspectionTypeId !== 2 ? null : <>
                        <PaginationTable
                          noPagination={true}
                          labels={['Criteria', 'Result']}
                          elements={[
                            [<span className='defaultMediumText boldText defaultTableElement'>GGI Flow Rate:</span>,
                            <span className='defaultMediumText defaultTableElement'>{inspectionData.flowRate} gallons per minute</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>% FOG and Solids:</span>,
                            <span className='defaultMediumText defaultTableElement'>{inspectionData.percTotalSolids}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>HGI completely emptied and cleaned before inspection?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.clean)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Access to all chambers is available for monthly cleanings?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.access)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Flow control device is installed and visible on the inlet side of the trap?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.flowControlInletSide)}</span>,],

                            ...(!inspectionData.flowControlInletSide ? [] : [
                              [<span className='defaultMediumText boldText defaultTableElement'>Flow control device orientation is correct?</span>,
                              <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.controlOriented)}</span>,],

                              [<span className='defaultMediumText boldText defaultTableElement'>HGIs flow control device is sufficiently vented?</span>,
                              <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.controlVented)}</span>,],
                            ]),

                            [<span className='defaultMediumText boldText defaultTableElement'>HGI has no visible holes or leaks and shows no significant signs of deterioration?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.condition)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>The baffle is secure and operational?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.baffle)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>HGI is directly connected to city sewer (no air gaps)?</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.sewerConnected)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Clean out covers are not missing, damaged, or grated? If not present, N/A.</span>,
                            <span className='defaultMediumText defaultTableElement'>{valueToString(inspectionData.coCoverIssue)}</span>,],

                            [<span className='defaultMediumText boldText defaultTableElement'>Inspection Result:</span>,
                            (inspectionData.inspectionPassed ? <span className='defaultMediumText successText boldText defaultTableElement'>PASSED</span> : <span className='defaultMediumText dangerText boldText defaultTableElement'>FAILED</span>),],
                          ]}
                        />

                        {
                          inspectionData.inspectionPassed ? null : <>
                            <span className='defaultMediumText boldText viewInspectionMaxWidth'>Corrective Action Response:</span>
                            <span className='defaultMediumText'>{inspectionData.correctiveActions}</span>

                            <span className='defaultMediumText boldText viewInspectionMaxWidth'>Expected Completion Date:</span>
                            <span className='defaultMediumText'>{new Date(inspectionData.correctionsExpectedDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</span>
                          </>
                        }
                      </>
                    }
                  </div>

                  <div className='smallButtonWrapper mediumMarginTop'>
                    <div className='smallButton' onClick={() => history.goBack()}>
                      <span>Back</span>
                    </div>
                    <div className='smallButton' onClick={handleDownloadReport}>
                      {downloading ? <IonSpinner name='crescent' /> : <span>Download</span>}
                    </div>
                  </div>
                </> : null
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ViewInspection;
