import { useIonToast } from '@ionic/react';

export const useCustomToast = () => {
  const [present] = useIonToast();

  const presentToast = (messageIn: string, toastClass: 'goodToast' | 'badToast') => {
    present({
      message: messageIn,
      duration: 5000,
      position: 'bottom',
      translucent: false,
      cssClass: toastClass,
    });
  }

  return presentToast;
}
