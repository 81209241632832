import { Fragment, cloneElement, memo, useState } from 'react';
import { LuChevronFirst, LuChevronLast, LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import './PaginationTable.css';

interface PaginationTableProps {
  labels: string[];
  elements: any[][];
  cssClasses?: string;
  noPagination?: boolean;
}

const PaginationTable: React.FC<PaginationTableProps> = ({ labels, elements, cssClasses, noPagination }) => {

  const tableLabelClassMap = {
    0: ' defaultTableLabelLeft',
    [labels.length - 1]: (noPagination ? ' defaultTableLabelRight' : ''),
  }
  const tableElementClassMap = {
    0: ' defaultTableElementLeft',
    [labels.length]: ' defaultTableElementRight',
  }
  const rowsPerPage = noPagination ? 100 : 25;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const calcPageNumber = () => Math.ceil(elements.length / rowsPerPage);
  const canGoBackwards = () => currentPage > 1;
  const canGoForwards = () => currentPage < calcPageNumber();


  return (
    <div className={`defaultTable ${cssClasses}`} style={{ gridTemplateColumns: `${labels.map(() => '1fr').join(' ')}${noPagination ? '' : ' auto'}` }}>
      {
        labels.map((label: string, index: number) =>
          <span key={index} className={`defaultMediumText defaultTableLabel${tableLabelClassMap[index] || ''}`}>
            {label}
          </span>
        )
      }
      {
        noPagination ? null :
          <div className='defaultMediumText defaultTableLabel defaultTableLabelRight pagination'>
            {
              elements.length <= rowsPerPage ? null : <>
                <span className='defaulTablePaginationValue'>{`${(rowsPerPage * (currentPage - 1)) + 1}-${Math.min(elements.length, rowsPerPage * currentPage)} of ${elements.length}`}</span>
                <LuChevronFirst className={`defaultTablePaginationButton${canGoBackwards() ? '' : ' defaultTablePaginationButtonDisabled'}`} onClick={() => canGoBackwards() && setCurrentPage(1)} />
                <LuChevronLeft className={`defaultTablePaginationButton${canGoBackwards() ? '' : ' defaultTablePaginationButtonDisabled'}`} onClick={() => canGoBackwards() && setCurrentPage((page: number) => page - 1)} />
                <LuChevronRight className={`defaultTablePaginationButton${canGoForwards() ? '' : ' defaultTablePaginationButtonDisabled'}`} onClick={() => canGoForwards() && setCurrentPage((page: number) => page + 1)} />
                <LuChevronLast className={`defaultTablePaginationButton${canGoForwards() ? '' : ' defaultTablePaginationButtonDisabled'}`} onClick={() => canGoForwards() && setCurrentPage(calcPageNumber())} />
              </>
            }
          </div>
      }

      {
        elements
          .slice((rowsPerPage * (currentPage - 1)), (rowsPerPage * currentPage))
          .map((row: any[], rowIndex: number) =>
            <Fragment key={rowIndex}>
              {
                row.map((element: any, index: number) =>
                  cloneElement(element, {
                    key: `${rowIndex}${index}`,
                    className: `${element.props.className || ''}${rowIndex === elements.slice((rowsPerPage * (currentPage - 1)), (rowsPerPage * currentPage)).length - 1 ? (tableElementClassMap[index] || '') : ''}`,
                  })
                )
              }
            </Fragment>
          )
      }
    </div>
  );
};

export default memo(PaginationTable);
